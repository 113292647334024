import { useEffect, useState } from "react";
import http from "../../../utils/http";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import { profileCard } from "./style";
import { PatientIconPen } from "../../Common/assets/CommonIcons";
import { DateTime } from "luxon";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

const ProfileCard = ({
  participantData,
  steps,
  sleep,
  cvd,
  ldl,
  openModal,
  hasMasterAdminAccess,
  hasHealthCoachAccess,
  hasClinicalAssistantAccess,
  startConsultation,
}: any) => {
  const [enableButton, setEnableButton] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [email, setEmail] = useState("");

  const handleShowEmail = () => {
    setShowEmail((prev) => !prev);
    if (!showEmail) {
      const getEmail = async () => {
        try {
          const res: AxiosResponse = await http.get(
            `participants/${participantData?.userId}/email`
          );
          setEmail(res.data.data);
        } catch (error) {
          errorToastMessage(error as Error);
        }
      };
      getEmail();
    } else {
      setEmail("");
    }
  };

  // const [, setDate] = useState<any>();
  // const time = participantData?.nextMeeting
  //   ? DateTime.fromFormat(participantData?.nextMeeting, "dd LLLL yyyy hh:mm a")
  //   : null;
  // const hours = time ? time.diffNow("hours").toObject().hours : 0;

  // const callback = useCallback(() => {
  //   setDate(new Date());
  // }, [setDate]);

  // useInterval(callback, participantData?.nextMeeting ? 120000 : null);

  useEffect(() => {
    let timeoutId: any;
    const calcRemainingTime = () => {
      const time = DateTime?.fromFormat(
        participantData?.nextMeeting,
        "dd LLLL yyyy hh:mm a"
      );

      const timeDifference = time
        ?.diffNow()
        ?.minus({ minutes: 15 })
        ?.toMillis();

      if (timeDifference <= 0) {
        setEnableButton(true);
      } else {
        timeoutId = setTimeout(() => {
          setEnableButton(true);
        }, timeDifference);
      }
    };

    if (participantData?.nextMeeting) {
      calcRemainingTime();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [participantData?.nextMeeting]);

  return (
    <Box sx={profileCard}>
      <Box>
        <Typography
          variant="subtitle1"
          fontWeight={"bold"}
          mb={1}
          color="#444444"
        >
          Name
        </Typography>
        <Typography variant="subtitle2" textTransform={"capitalize"}>
          {participantData?.nickname || "-"}
        </Typography>
      </Box>
      {!hasHealthCoachAccess && (
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            mb={1}
            color="#444444"
          >
            User ID
          </Typography>
          <Typography variant="subtitle2">
            {participantData?.studyNumber || "-"}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography
          variant="subtitle1"
          fontWeight={"bold"}
          mb={1}
          color="#444444"
        >
          Gender
        </Typography>
        <Typography variant="subtitle2" textTransform={"capitalize"}>
          {participantData?.gender || "-"}
        </Typography>
      </Box>
      {/* <Box>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            mb={1}
            color="#444444"
          >
            Language
          </Typography>
          <Typography variant="subtitle2" textTransform={"capitalize"} >
            {participantData?.lang ? langMap[participantData?.lang] : "-"}
          </Typography>
        </Box> */}
      <Box>
        <Typography
          variant="subtitle1"
          fontWeight={"bold"}
          mb={1}
          color="#444444"
        >
          Ethnicity
        </Typography>
        <Typography variant="subtitle2" textTransform={"capitalize"}>
          {participantData?.ethnicity || "-"}
        </Typography>
      </Box>

      {/* {hasHealthCoachAccess && (
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              mb={1}
              color="#444444"
            >
              Age
            </Typography>
            <Typography variant="subtitle2" textTransform={"capitalize"} >
              {participantData?.age || "-"}
            </Typography>
          </Box>
        )} */}
      {/* {!hasHealthCoachAccess && (
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              mb={1}
              color="#444444"
            >
              Participant Type
            </Typography>
            <Typography variant="subtitle2" textTransform={"capitalize"} >
              {participantData?.isSelf}
            </Typography>
          </Box>
        )} */}
      {!hasHealthCoachAccess && !hasClinicalAssistantAccess && (
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            mb={1}
            color="#444444"
          >
            Risk Category
          </Typography>
          <Typography
            variant="subtitle2"
            textTransform={"capitalize"}

            // sx={{
            //   width: "max-content",
            // }}
          >
            {participantData?.riskCategory || "-"}
          </Typography>
        </Box>
      )}
      {hasHealthCoachAccess && (
        <>
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              mb={1}
              color="#444444"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              CVD risk
              {/* <IconButton
                sx={{ p: 0.5 }}
                // onClick={() => openCvdModal(true, "cvd")}
              >
                <PatientIconPen />
              </IconButton> */}
            </Typography>
            <Typography variant="subtitle2" textTransform={"capitalize"}>
              {cvd || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              mb={1}
              color="#444444"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              LDL
              {/* <IconButton
                sx={{ p: 0.5 }}
                // onClick={() => openCvdModal(true, "ldl")}
              >
                <PatientIconPen />
              </IconButton> */}
            </Typography>
            <Typography variant="subtitle2" textTransform={"capitalize"}>
              {ldl || "-"}
            </Typography>
          </Box>
        </>
      )}
      {!hasClinicalAssistantAccess && (
        <>
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              mb={1}
              color="#444444"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Steps target
              {hasHealthCoachAccess && (
                <IconButton
                  sx={{ p: 0.5 }}
                  onClick={() => openModal(true, "Steps")}
                >
                  <PatientIconPen />
                </IconButton>
              )}
            </Typography>
            <Typography variant="subtitle2" textTransform={"capitalize"}>
              {steps || "-"}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              mb={1}
              color="#444444"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Sleep goal
              {hasHealthCoachAccess && (
                <IconButton
                  sx={{ p: 0.5 }}
                  onClick={() => openModal(true, "Sleep")}
                >
                  <PatientIconPen />
                </IconButton>
              )}
            </Typography>
            <Typography variant="subtitle2" textTransform={"capitalize"}>
              {sleep || "-"}
            </Typography>
          </Box>
        </>
      )}

      {hasClinicalAssistantAccess && (
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            mb={1}
            color="#444444"
          >
            Health coach
          </Typography>
          <Typography
            variant="subtitle2"
            textTransform={"capitalize"}

            // sx={{
            //   width: "max-content",
            // }}
          >
            {participantData?.coachName || "-"}
          </Typography>
        </Box>
      )}

      {hasHealthCoachAccess && (
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            mb={1}
            color="#444444"
          >
            Learning pathway
          </Typography>
          <Typography variant="subtitle2" textTransform={"capitalize"}>
            {participantData?.riskCategory || "-"}
          </Typography>
        </Box>
      )}

      <Box>
        <Typography
          variant="subtitle1"
          fontWeight={"bold"}
          mb={1}
          color="#444444"
        >
          Onboarding date
        </Typography>
        <Typography variant="subtitle2" textTransform={"capitalize"}>
          {participantData?.onboardingDate || "-"}
        </Typography>
      </Box>

      {hasHealthCoachAccess && (
        <Box
          sx={{
            gridColumnEnd: "span 2",
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            mb={1}
            color="#444444"
          >
            Next meeting
          </Typography>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Typography
              variant="subtitle1"
              textTransform={"capitalize"}
              sx={{ color: "#0E9F6E" }}
            >
              {participantData?.nextMeeting || "-"}
            </Typography>
            {participantData?.consultationId && (
              <Box sx={{ position: "relative", bottom: 10 }}>
                <Button
                  variant="contained"
                  onClick={startConsultation}
                  disabled={!enableButton}
                  // disabled={hours && hours > 0.25 ? true : false}
                >
                  Start consultation
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {hasMasterAdminAccess && (
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            mb={1}
            color="#444444"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Email
            <Tooltip title="click to view email">
              <IconButton onClick={handleShowEmail} size="small" sx={{ pl: 1 }}>
                {showEmail ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
          </Typography>

          <Typography variant="subtitle2">{email || "-"}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProfileCard;
