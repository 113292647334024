import { SxProps } from "@mui/material";

export const AuthLayout: SxProps = {
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // backgroundImage: `url("/bg_img.png")`,
  // backgroundPosition: "top",
  // backgroundSize: "cover",
  overflow: "auto",
};

export const SignInWrapper: SxProps = {
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  // boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  borderRadius: "16px",
  minWidth: "500px",
  width: "35%",
  padding: "64px",
};

export const AuthRadioSelector: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  mb: 3,
};

export const FormHelperTextContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  mt: 3,
};
