import ContentBuilder from "./ContentBuilder/ContentBuilder";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { educationResponse } from "../../../utils/education";
import BuilderPreview from "../Preview/BuilderPreview";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { BuilderWrapper } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setDefaultSections,
  setDefaults,
  setLoading,
} from "../../../Redux/reducers/reflectionBuilderSlice";
import Header from "./Header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReflectionBuilder = () => {
  const dispatch = useAppDispatch();
  const id = useQuery().get("id");
  const navigate = useNavigate();

  const { previewMode, loading } = useAppSelector(
    (state) => state.reflectionBuilder
  );

  useEffect(() => {
    const fetchLessonDetails = async () => {
      if (id) {
        try {
          dispatch(setLoading(true));
          const res: AxiosResponse = await http.get(`/self-reflection/${id}`);
          const pageSections = educationResponse(res.data?.data?.sections);
          dispatch(
            setDefaults({
              lesson: { pages: [], title: "", position: 0 },
              title: res.data?.data?.title,
              questionsMandatory: res.data.data?.areQuestionsMandatory || false,
              sections: pageSections,
              loading: false,
              editMode: true,
              previewMode: false,
              lang: res.data.data?.lang || "en",
            })
          );
        } catch (err) {
          errorToastMessage(err as Error);
          navigate(-1);
        }
      } else {
        dispatch(setDefaultSections());
      }
    };
    fetchLessonDetails();
    return () => {
      dispatch(
        setDefaults({
          lesson: { pages: [], title: "", position: 0 },
          title: "",
          questionsMandatory: false,
          sections: [],
          loading: false,
          editMode: false,
          previewMode: false,
          lang: "en",
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  return previewMode ? (
    <BuilderPreview pageId={""} />
  ) : (
    <Box sx={{ height: "100%" }}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Header />
      <Box sx={BuilderWrapper}>
        <ContentBuilder />
        {/* {editMode ? <ContentBuilder /> : <SectionBuilderWrapper />} */}
      </Box>
    </Box>
  );
};

export default ReflectionBuilder;
