import { Box } from "@mui/material";

import ChatbotHeader from "./ChatbotHeader";
import ChatbotList from "./ChatbotList";
import { useAppSelector } from "../../Redux/hooks";
import ChatbotUrlLoader from "./ChatbotUrlLoader";
import ChatbotUrlSetter from "./ChatbotUrlSetter";

const Chatbot = () => {
  // const dispatch = useAppDispatch();
  const {
    // type,
    urlLoaded,
  } = useAppSelector((state) => state.chatbot);

  // const handleChange = (_: any, newValue: string) => {
  //   dispatch(setChatbotType(newValue));
  // };

  return urlLoaded ? (
    <>
      <ChatbotHeader />
      {/* <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Conversations" value="general" {...a11yProps(0)} />
          <StyledTab label="FAQ" value="faq" {...a11yProps(1)} />
        </StyledTabs>
      </Box> */}
      <Box
        sx={{
          p: 2.5,
          height: "calc(100% - 85px)",
          overflow: "auto",
        }}
      >
        {/* <MainTabPanel value={type} index={"general"}> */}
        <ChatbotList />
        {/* </MainTabPanel>
        <MainTabPanel value={type} index={"faq"}>
          <ChatbotList />
        </MainTabPanel> */}
      </Box>
      <ChatbotUrlSetter />
    </>
  ) : (
    <ChatbotUrlLoader />
  );
};

export default Chatbot;
