import { v4 as uuid } from "uuid";

import http from "./http";
import { CbBuilderStep, CbBuilderStepType } from "./../types/chatbotBuilder";
import {
  CalendarIcon,
  GptIcon,
  LongTextIcon,
  MCQIcon,
  NumberIcon,
  ShortTextIcon,
  SliderIcon,
  YesNoIcon,
} from "../Components/Chatbot/Builder/Icons";
import { DateTime } from "luxon";

export const NumberMap = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const CbBuilderStepTypes: {
  label: string;
  type: CbBuilderStepType;
}[] = [
  {
    label: "Multiple Choice",
    type: "multiple_choice",
  },
  {
    label: "Short Text",
    type: "short_text",
  },
  {
    label: "Long Text",
    type: "long_text",
  },
  {
    label: "Yes/No",
    type: "yes_no",
  },
  {
    label: "Number",
    type: "number",
  },
  {
    label: "Calendar",
    type: "calendar",
  },
  {
    label: "Slider",
    type: "slider",
  },
  {
    label: "LLM response",
    type: "gpt",
  },
];

export const getStepLabelFromType = (type: CbBuilderStepType) => {
  return CbBuilderStepTypes.find((step) => step.type === type)?.label;
};

export const CbBuilderStepCategories = {
  choice: ["multiple_choice", "yes_no"],
  number: ["number", "calendar", "slider"],
  text: ["short_text", "long_text"],
  gptText: ["gpt"],
};

export const CbBuilderStepIconMap: any = {
  multiple_choice: MCQIcon,
  short_text: ShortTextIcon,
  long_text: LongTextIcon,
  yes_no: YesNoIcon,
  number: NumberIcon,
  calendar: CalendarIcon,
  slider: SliderIcon,
  gpt: GptIcon,
};

export const populateBasicFields = (type: string) => {
  const newStep: any = {
    id: uuid(),
    codeName: "",
    name: "",
    botMessages: [
      {
        id: uuid(),
        type: "text",
        text: "",
        delay: 1000,
        attachmentUrl: null,
      },
    ],
  };
  if (CbBuilderStepCategories["choice"].includes(type)) {
    newStep["botResponses"] = [
      {
        id: uuid(),
        answer: "",
        isDefault: false,
        operator: "eq",
        nextStep: "default",
        responseMsgs: [{ id: uuid(), text: "", type: "text", delay: 1000 }],
      },
    ];
    if (type === "yes_no") {
      newStep["options"] = [
        { id: uuid(), text: "yes" },
        { id: uuid(), text: "no" },
      ];
    } else if (type === "multiple_choice") {
      newStep["options"] = [{ id: uuid(), text: "", imageUrl: null }];
    }
  } else {
    newStep["botResponses"] = [
      {
        id: uuid(),
        answer: "",
        operator: "",
        isDefault: true,
        nextStep: "default",
        responseMsgs: [{ id: uuid(), text: "", type: "text", delay: 1000 }],
      },
    ];
  }
  if (type === "slider") {
    newStep["sliderConfig"] = {
      minLabel: "",
      maxLabel: "",
      min: "",
      max: "",
      unit: "",
      default: "",
      stepSize: "",
    };
  }
  return newStep;
};

const numberOperators = [
  { label: "Equals", value: "eq" },
  { label: "Not Equal", value: "not_eq" },
  { label: "Greater than", value: "gt" },
  { label: "Lesser than", value: "lt" },
];

const textOperators = [
  { label: "Contains", value: "contains" },
  { label: "Not Contains", value: "not_in" },
];

export const StepOperatorMap: any = {
  gpt: textOperators,
  short_text: textOperators,
  long_text: textOperators,
  number: numberOperators,
  calendar: numberOperators,
  slider: numberOperators,
  multiple_choice: [],
  yes_no: [],
};

export const createStep = (type: CbBuilderStepType): CbBuilderStep => {
  let step: any = CbBuilderStepTypes.find((s) => s.type === type);
  if (step) {
    step = {
      ...step,
      ...populateBasicFields(type),
    };
  }

  return step;
};

export const cloneStep = (step: CbBuilderStep): CbBuilderStep => {
  let cloned = JSON.parse(JSON.stringify(step));
  cloned = {
    ...cloned,
    id: uuid(),
    codeName: step.codeName
      ? step.codeName + " copy"
      : getStepLabelFromType(step.type) + " copy",
  };
  if (cloned.options && cloned.options?.length) {
    const newOptionsMap: any = {};
    cloned.options = cloned.options.map((option: any) => {
      const newId = uuid();
      newOptionsMap[option.id] = newId;
      return {
        ...option,
        id: newId,
      };
    });
    cloned.botResponses.forEach((res: any) => {
      if (res?.answer && newOptionsMap[res.answer]) {
        return (res.answer = newOptionsMap[res.answer]);
      }
    });
  }
  return cloned;
};

export const createNewOption = () => {
  return {
    id: uuid(),
    label: "",
    response: "",
    nextStep: "",
  };
};

const stepViewFormatter = (newSteps: any[]) => {
  const finalSteps = [];
  for (let i = 0; i < newSteps.length; i++) {
    const step = newSteps[i];
    const botMessages = step.botMessages
      .slice()
      .sort((a: any, b: any) => {
        return a.position - b.position;
      })
      .map((bot: any) => {
        return {
          id: bot?.id,
          type: bot?.type,
          text: bot?.text,
          delay: bot?.delay || 1000,
          attachmentUrl: bot?.attachmentUrl,
        };
      });
    const updatedStep: any = {
      id: step?.id,
      codeName: step?.codeName,
      name: step?.name,
      type: step?.type,
      botMessages,
      position: step?.position,
      optionSearchable: step?.optionSearchable || false,
      isReminder: step?.isReminder || false,
      reminderBotId: step?.reminderBotId || null,
    };
    if (CbBuilderStepCategories["choice"].includes(updatedStep.type)) {
      const options = step.options
        .slice()
        .sort((a: any, b: any) => {
          return a.position - b.position;
        })
        .map((bot: any) => {
          return {
            id: bot.id,
            text: bot.text,
            imageUrl: bot.imageUrl,
          };
        });
      updatedStep.options = options;
    }
    if (updatedStep.type === "slider") {
      updatedStep.sliderConfig = {
        min: step.stepSettings?.minValue,
        max: step.stepSettings?.maxValue,
        default: step.stepSettings?.defaultValue,
        minLabel: step.stepSettings?.leftLabel,
        maxLabel: step.stepSettings?.rightLabel,
        unit: step.stepSettings?.unit,
        stepSize: step.stepSettings?.stepSize,
      };
    }
    updatedStep.botResponses = step.stepResponses.map((stepResponse: any) => {
      const response: any = {
        id: stepResponse.id,
        isDefault: stepResponse.isDefault || false,
        operator: stepResponse?.operator || null,
        nextStep:
          stepResponse.nextStepId ||
          (stepResponse.exitAfter ? "exit" : "default"),
        responseMsgs: stepResponse.stepResponseMessages
          .slice()
          .sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((msg: any) => {
            const unitId = msg.educationLessonId
              ? msg.educationLessonUnitId || "all"
              : null;
            const careUnitId = msg.careEducationLessonId
              ? msg.careEducationLessonUnitId || "all"
              : null;
            return {
              id: msg.id,
              text: msg.text || "",
              type: msg.type,
              delay: msg.delay || 1000,
              attachmentUrl: msg.attachmentUrl || undefined,
              educationLessonId: msg.educationLessonId || null,
              careEducationLessonId: msg.careEducationLessonId || null,
              educationLessonUnitId: unitId,
              careEducationLessonUnitId: careUnitId,
              educationLessonPageId: unitId
                ? unitId === "all"
                  ? null
                  : msg.educationLessonPageId || "all"
                : null,
              careEducationLessonPageId: careUnitId
                ? careUnitId === "all"
                  ? null
                  : msg.careEducationLessonPageId || "all"
                : null,
              botId: msg.botId || undefined,
              botStepId: msg.botStepId || undefined,
              isNotificationEnabled: msg.isNotificationEnabled || false,
              notification: {
                title: msg?.notification?.title || "",
                body: msg?.notification?.body || "",
                triggerIn: msg?.notification?.triggerIn || 0,
              },
            };
          }),
      };
      if (CbBuilderStepCategories["choice"].includes(updatedStep.type)) {
        response.answer = stepResponse.stepOptionId || null;
      } else if (CbBuilderStepCategories["text"].includes(step.type)) {
        response.answer = stepResponse.compareText || null;
      } else if (step.type === "calendar") {
        response.answer = stepResponse.compareDate
          ? stepResponse.compareDate
          : null;
      } else if (CbBuilderStepCategories["number"].includes(step.type)) {
        response.answer = stepResponse.compareNumber;
      }
      return response;
    });
    finalSteps.push(updatedStep);
  }
  finalSteps.sort((a: any, b: any) => {
    return a.position - b.position;
  });
  return finalSteps;
};

export const stepViewFormat = async (steps: any[]) => {
  const promises: any[] = [];
  steps.forEach((step) => {
    promises.push(
      http.get(`bots/steps/${step?.id}`).then((res) => {
        return res.data.data;
      })
    );
  });
  const newSteps = await Promise.all(promises);
  const finalSteps = stepViewFormatter(newSteps);
  return finalSteps;
};

export const stepBasicDetailsSubmit = (step: any, index: number) => {
  const formattedStep = {
    id: step.id,
    type: step.type,
    codeName: step.codeName,
    name: step.name,
    optionSearchable: step.optionSearchable || false,
    isReminder: step.isReminder || false,
    reminderBotId: step.reminderBotId || null,
  };
  if (!formattedStep.name.trim()) {
    throw new Error(`Step name at position ${index + 1} cannot be empty`);
  }
  if (!formattedStep.codeName.trim()) {
    throw new Error(`Step code name at position ${index + 1} cannot be empty`);
  }
  return formattedStep;
};

export const stepBotMessagesSubmit = (step: any, StepIndex: number) => {
  const detail = `step ${step.name} at position ${StepIndex + 1}`;
  const botMessages = step.botMessages;
  if (botMessages.length < 1) {
    throw new Error(`Atleast one bot message is required for ${detail}`);
  }
  const updatedBotMessages = botMessages.map((message: any, index: number) => {
    if (!message.text.trim()) {
      throw new Error(
        `${
          message.type === "text" ? "Chat text" : message.type + " title"
        } cannot be empty for bot messages in ${detail}`
      );
    }
    if (message.type !== "text" && message.type !== "reference") {
      if (!message.attachmentUrl) {
        throw new Error(
          `Chat ${message.type} cannot be empty for bot messages in ${detail}`
        );
      }
    }
    const newMessage = {
      ...message,
      position: index + 1,
    };
    if (!newMessage.delay) {
      newMessage.delay = 1000;
    }
    return newMessage;
  });
  return updatedBotMessages;
};

export const stepAnswerOptionsSubmit = (step: any, StepIndex: number) => {
  const detail = `step ${step.name} at position ${StepIndex + 1}`;
  const options = step.options;
  if (options.length < 1) {
    throw new Error(`Atleast one answer options is required for ${detail}`);
  }
  let numOfImages = 0;
  const updatedOptions = options.map((opt: any, index: number) => {
    if (!opt.text.trim()) {
      throw new Error(`Answer option text cannot be empty in ${detail}`);
    }
    if (opt.imageUrl) {
      numOfImages++;
    }
    return {
      ...opt,
      position: index + 1,
    };
  });
  if (numOfImages > 0 && numOfImages !== options.length) {
    throw new Error(
      `Image should be present in all answer options in ${detail}`
    );
  }
  return updatedOptions;
};

export const stepBotResponsesSubmit = (
  step: any,
  StepIndex: number,
  stepIds: any[],
  isFaq: boolean
) => {
  const detail = `step ${step.name} at position ${StepIndex + 1}`;
  const botResponses = step.botResponses;
  if (botResponses.length < 1 && step?.type !== "gpt") {
    throw new Error(`Atleast one bot response is required for ${detail}`);
  }
  const optionIds = step.options ? step.options.map((opt: any) => opt.id) : [];
  let count = 0;
  const updatedBotResponses = botResponses.map((res: any) => {
    const response: any = {
      id: res.id,
      isDefault: res.isDefault || false,
      nextStepId: res.nextStep || null,
      stepResponseMessages: res.responseMsgs.map(
        (msg: any, resIndex: number) => {
          const unitId = msg.educationLessonId
            ? msg.educationLessonUnitId === "all"
              ? null
              : msg.educationLessonUnitId
            : null;
          const careUnitId = msg.careEducationLessonId
            ? msg.careEducationLessonUnitId === "all"
              ? null
              : msg.careEducationLessonUnitId
            : null;
          let notification = null;
          if (msg.isNotificationEnabled)
            notification = {
              title: msg?.notification?.title || "",
              body: msg?.notification?.body || "",
              triggerIn: msg?.notification?.triggerIn || 0,
            };
          return {
            id: msg.id,
            delay: msg.delay || 1000,
            text: msg.text || undefined,
            type: msg.type,
            educationLessonId: msg.educationLessonId || null,
            careEducationLessonId: msg.careEducationLessonId || null,
            educationLessonUnitId: unitId,
            careEducationLessonUnitId: careUnitId,
            educationLessonPageId: unitId
              ? msg.educationLessonPageId === "all"
                ? null
                : msg.educationLessonPageId
              : null,
            careEducationLessonPageId: unitId
              ? msg.careEducationLessonPageId === "all"
                ? null
                : msg.careEducationLessonPageId
              : null,
            position: resIndex + 1,
            attachmentUrl: msg.attachmentUrl || undefined,
            botId: msg.botId || undefined,
            botStepId: msg.botStepId || undefined,
            isNotificationEnabled: msg.isNotificationEnabled || false,
            notification: notification,
          };
        }
      ),
    };
    if (res.isDefault) {
      count++;
    }
    // if (res.responseMsgs.length < 1) {
    //   throw new Error(
    //     `Response messages cannot be empty for bot responses in ${detail}`
    //   );
    // }
    res.responseMsgs.forEach((msg: any) => {
      // if (
      //   !msg?.text?.trim() &&
      //   msg.type !== "educationLesson" &&
      //   msg.type !== "bot"
      // ) {
      //   throw new Error(
      //     `Response messages cannot be empty for bot responses in ${detail}`
      //   );
      // }
      if (msg.isNotificationEnabled) {
        if (!msg?.notification?.title) {
          throw new Error(
            `Notification Title cannot be empty for bot responses in ${detail}`
          );
        }
        if (!msg?.notification?.body) {
          throw new Error(
            `Notification Body cannot be empty for bot responses in ${detail}`
          );
        }
      }
      if (msg.type === "educationLesson") {
        if (!msg?.educationLessonId) {
          throw new Error(
            `Please choose a ${
              isFaq ? "Patient " : ""
            }education lesson for response messages in ${detail}`
          );
        }
        if (!msg?.educationLessonUnitId) {
          throw new Error(
            `Please choose a ${
              isFaq ? "Patient " : ""
            }unit in the education lesson for response messages in ${detail}`
          );
        }
        if (
          msg?.educationLessonUnitId !== "all" &&
          !msg?.educationLessonPageId
        ) {
          throw new Error(
            `Please choose a ${
              isFaq ? "Patient " : ""
            }page in the education lesson for response messages in ${detail}`
          );
        }
        if (isFaq && !msg?.careEducationLessonId) {
          throw new Error(
            `Please choose a Caregiver education lesson for response messages in ${detail}`
          );
        }
        if (isFaq && !msg?.careEducationLessonUnitId) {
          throw new Error(
            `Please choose a Caregiver unit in the education lesson for response messages in ${detail}`
          );
        }
        if (
          isFaq &&
          msg?.careEducationLessonUnitId !== "all" &&
          !msg?.careEducationLessonPageId
        ) {
          throw new Error(
            `Please choose a Caregiver page in the education lesson for response messages in ${detail}`
          );
        }
      }
      if (msg.type === "bot") {
        if (!msg?.botId || !msg?.botStepId) {
          throw new Error(
            `Please choose a bot to redirect for response messages in ${detail}`
          );
        }
      }
      if (msg.type === "image") {
        if (!msg?.attachmentUrl) {
          throw new Error(
            `Please upload a image for response messages in ${detail}`
          );
        }
      }
      if (msg.type === "link") {
        if (!msg?.attachmentUrl) {
          throw new Error(
            `Please enter a link for response messages in ${detail}`
          );
        }
      }
    });
    if (!res.isDefault) {
      if (CbBuilderStepCategories["choice"].includes(step.type)) {
        response.stepOptionId = res.answer;
        if (optionIds.length > 0 && !optionIds.includes(res.answer)) {
          throw new Error(
            `Answer option cannot be empty for bot responses in ${detail}`
          );
        }
      } else {
        if (!res.operator) {
          throw new Error(
            `Operator cannot be empty for bot responses in ${detail}`
          );
        }
        response.operator = res.operator || undefined;
        if (CbBuilderStepCategories["text"].includes(step.type)) {
          if (!res?.answer?.trim()) {
            throw new Error(
              `Answer option cannot be empty for bot responses in ${detail}`
            );
          }
          response.compareText = res.answer;
        } else if (step.type === "calendar") {
          if (!res?.answer) {
            throw new Error(
              `Enter a valid date for bot responses in ${detail}`
            );
          }
          const newDate = DateTime.fromFormat(res?.answer || "", "yyyy-LL-dd");
          response.compareDate = newDate.toISO({ includeOffset: false });
        } else if (CbBuilderStepCategories["number"].includes(step.type)) {
          if (res?.answer === undefined || res?.answer === "") {
            throw new Error(
              `Enter a valid Answer option for bot responses in ${detail}`
            );
          }
          response.compareNumber = Number(res.answer);
        }
      }
    }
    if (res.nextStep === "exit") {
      response.exitAfter = true;
    }
    if (
      (res.nextStep !== "default" && !stepIds.includes(res.nextStep)) ||
      res.nextStep === "default"
    ) {
      response.nextStepId = undefined;
    }
    return response;
  });
  if (count > 1) {
    throw new Error(`Only one response can be default for ${detail}`);
  }
  return updatedBotResponses;
};

export const stepSliderSubmit = (step: any, StepIndex: number) => {
  const detail = `step ${step.name} at position ${StepIndex + 1}`;
  const slider = {
    minValue: step.sliderConfig?.min,
    maxValue: step.sliderConfig?.max,
    defaultValue: step.sliderConfig?.default,
    leftLabel: step.sliderConfig?.minLabel,
    rightLabel: step.sliderConfig?.maxLabel,
    unit: step.sliderConfig?.unit,
    stepSize: step.sliderConfig?.stepSize,
  };
  if (typeof slider.minValue === "undefined" || slider.minValue === "") {
    throw new Error(`Slider min value cannot be empty in ${detail}`);
  }
  if (typeof slider.maxValue === "undefined" || slider.maxValue === "") {
    throw new Error(`Slider max value cannot be empty in ${detail}`);
  }
  if (
    typeof slider.defaultValue === "undefined" ||
    slider.defaultValue === ""
  ) {
    throw new Error(`Slider default value cannot be empty in ${detail}`);
  }
  if (!slider.stepSize) {
    throw new Error(`Slider step size cannot be empty in ${detail}`);
  }
  if (!slider.unit) {
    throw new Error(`Slider unit cannot be empty in ${detail}`);
  }
  if (!slider.leftLabel) {
    throw new Error(`Slider min label cannot be empty in ${detail}`);
  }
  if (!slider.rightLabel) {
    throw new Error(`Slider max label cannot be empty in ${detail}`);
  }
  if (+slider.minValue > +slider.maxValue) {
    throw new Error(
      `Slider min value should be less than the slider max value in ${detail}`
    );
  }
  if (+slider.defaultValue > +slider.maxValue) {
    throw new Error(
      `Slider default value should be less than the slider max value in ${detail}`
    );
  }
  if (+slider.defaultValue < +slider.minValue) {
    throw new Error(
      `Slider default value should be greater than the slider min value in ${detail}`
    );
  }
  if (+slider.defaultValue % +slider.stepSize !== 0) {
    throw new Error(
      `Slider default value should be divisible by the step size in ${detail}`
    );
  }
  if (+slider.minValue % +slider.stepSize !== 0) {
    throw new Error(
      `Slider min value should be divisible by the step size in ${detail}`
    );
  }
  if (+slider.maxValue % +slider.stepSize !== 0) {
    throw new Error(
      `Slider max value should be divisible by the step size in ${detail}`
    );
  }
  return slider;
};

export const stepSubmitFormat = (steps: any[], isFaq: boolean) => {
  let formattedSteps = steps.map((step, index) => {
    const basicDetails = stepBasicDetailsSubmit(step, index);
    const botMessages = stepBotMessagesSubmit(step, index);
    const updatedStep: any = {
      position: index + 1,
      ...basicDetails,
      botMessages,
      botResponses: step.botResponses,
    };
    if (step.type === "slider") {
      updatedStep["stepSettings"] = stepSliderSubmit(step, index);
    }
    if (CbBuilderStepCategories["choice"].includes(step.type)) {
      const options = stepAnswerOptionsSubmit(step, index);
      updatedStep["options"] = options;
    }
    return updatedStep;
  });
  const stepIds = formattedSteps.map((step: any) => step.id);
  formattedSteps = formattedSteps.map((step: any, index: number) => {
    const botResponses = stepBotResponsesSubmit(step, index, stepIds, isFaq);
    const newStep = {
      ...step,
      stepResponses: botResponses,
    };
    delete newStep["botResponses"];
    return newStep;
  });
  const codeNames = new Set();
  formattedSteps.forEach((step: any) => {
    codeNames.add(step.codeName);
  });
  if (codeNames.size !== formattedSteps.length) {
    throw new Error(`Codenames of the steps cannot be same`);
  }
  return formattedSteps;
};

export const getPreviewMessages = (
  step: any,
  firstMsgActive: boolean = true
) => {
  const msgs =
    (step?.botMessages || step?.stepMessages)
      ?.map((msg: any, index: number) => {
        return {
          id: uuid(),
          position: msg?.position,
          text: msg?.text,
          type: msg?.type,
          delay: msg?.delay,
          attachmentUrl: msg?.attachmentUrl,
          chatType: "bot_message",
          active: index === 0 ? firstMsgActive : false,
        };
      })
      .sort((a: any, b: any) => {
        return a.position - b.position;
      }) || [];
  if (step?.options?.length > 0 || step?.stepOptions?.length > 0) {
    msgs.push({
      id: uuid(),
      botId: step?.botId,
      stepId: step?.id,
      chatType: "bot_choice",
      stepType: step?.type,
      active: false,
      options: (step?.options || step?.stepOptions)
        ?.map((opt: any) => {
          return {
            id: opt?.id,
            position: opt?.position,
            text: opt?.text,
            imageUrl: opt?.imageUrl,
          };
        })
        .sort((a: any, b: any) => {
          return a?.position - b?.position;
        }),
    });
  } else {
    msgs.push({
      id: uuid(),
      botId: step?.botId,
      stepId: step?.id,
      chatType: "bot_choice",
      stepType: step?.type,
      active: false,
      stepSettings: step?.type === "slider" ? step?.stepSettings : undefined,
    });
  }
  return msgs;
};

export const getPreviewStepMessages = (step: any) => {
  const msgs: any[] = [];
  let stopFlag = false;
  let madeActive = false;
  step?.stepResponseMessages
    ?.slice()
    ?.sort((a: any, b: any) => {
      return a.position - b.position;
    })
    ?.forEach((msg: any, index: number) => {
      if (stopFlag || msg.isNotificationEnabled) {
        return;
      }
      if (msg.type === "educationLesson") {
        stopFlag = true;
        let makeActive = index === 0 || !madeActive ? true : false;
        if (msg?.text) {
          makeActive = false;
          msgs.push({
            id: uuid(),
            text: msg?.text,
            type: msg?.type,
            delay: msg?.delay,
            chatType: "bot_message",
            active: index === 0 || !madeActive ? true : false,
          });
        }
        const lessonText = `${"Lesson - " + msg?.educationLesson?.name}${
          msg?.educationLessonUnit?.title
            ? ", Unit - " + msg?.educationLessonUnit?.title
            : ""
        }${
          msg?.educationLessonPage?.title
            ? ", Page - " + msg?.educationLessonPage?.title
            : ""
        }`;
        msgs.push({
          id: uuid(),
          text: `View ${lessonText.trim()}`,
          type: msg?.type,
          delay: msg?.delay,
          chatType: "bot_message",
          active: makeActive,
        });
      } else if (msg.type === "bot") {
        stopFlag = true;
        msgs.push({
          id: uuid(),
          text: msg?.text,
          type: msg?.type,
          delay: msg?.delay,
          chatType: "bot_message",
          active: index === 0 || !madeActive ? true : false,
          nextBotId: msg?.botId,
          nextStepId: msg?.botStepId,
        });
      } else if (
        msg.type === "food_diary" ||
        msg.type === "feet_diary" ||
        msg.type === "medication"
      ) {
        stopFlag = true;
        let makeActive = index === 0 || !madeActive ? true : false;
        if (msg?.text) {
          makeActive = false;
          msgs.push({
            id: uuid(),
            text: msg?.text,
            type: msg?.type,
            delay: msg?.delay,
            chatType: "bot_message",
            active: index === 0 || !madeActive ? true : false,
          });
        }
        let text =
          msg?.type === "food_diary"
            ? "View Food Diary"
            : msg.type === "feet_diary"
            ? "View Feet Diary"
            : "View Medication Diary";
        msgs.push({
          id: uuid(),
          text: text,
          type: msg?.type,
          delay: msg?.delay,
          chatType: "bot_message",
          active: makeActive,
        });
      } else {
        msgs.push({
          id: uuid(),
          text: msg?.text,
          type: msg?.type,
          delay: msg?.delay,
          attachmentUrl: msg?.attachmentUrl,
          chatType: "bot_message",
          active: index === 0 || !madeActive ? true : false,
        });
      }
      madeActive = true;
    });
  return { stopFlag, msgs };
};

export const importChat = async (body: any, type: string) => {
  const botBody = {
    status: "draft",
    name: body.name,
    type: type,
    externalName: body.externalName || body.name,
    lang: body?.lang || "en",
    imageUrl: body.imageUrl || null,
    userType: body.userType || 0,
    isVisible: body.isVisible || false,
  };
  const res = await http.post(`/bots`, botBody);
  const botId = res.data.data.id;
  if (body.botSteps && body.botSteps.length > 0) {
    const steps = stepViewFormatter(body.botSteps);
    const sanitizedSteps = stepSubmitFormat(steps, type === "faq");
    const stepBody = {
      id: botId,
      status: "draft",
      botSteps: sanitizedSteps,
    };
    await http.post(`/bots/${botId}/build`, stepBody);
  }
  return botId;
};
