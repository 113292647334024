import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  Typography,
  Box,
  LinearProgress,
  Badge,
} from "@mui/material";
import {
  StyledTableCell,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
  NoDataContainer,
  StyledSortLabel,
} from "../Common/styles/table";
import { errorToastMessage } from "../../utils/toast";
import http from "../../utils/http";
import { DateTime } from "luxon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { getDateString } from "../../utils/notification";
import { setRefreshNotification } from "../../Redux/reducers/notificationSlice";

type Props = {
  sortOrder: string;
  setSortOrder: (order: string) => void;
  page: number;
  setPage: (page: number) => void;
};

const LiveChat = ({ sortOrder, setSortOrder, page, setPage }: Props) => {
  const [data, setData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };
  const { refreshNotifications } = useAppSelector(
    (state) => state.notification
  );
  let [, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        let url = `/notifications/chat/grouped?page=${
          page + 1
        }&size=${pageSize}`;
        if (sortOrder !== "") {
          url += `&order=${sortOrder}`;
        }
        const res = await http.get(url);
        const count = parseInt(res?.data?.data?.totalGroupedCount || "0");

        if (res?.data?.data?.data?.length === 0 && count > 0 && page > 0) {
          setPage(0);
          return;
        }

        const resData = res?.data?.data?.data?.map((item: any) => {
          return {
            id: item?.code,
            userId: item?.mostRecentEntry?.data?.data?.participantId,
            code: item?.code || "",
            name: item?.mostRecentEntry?.data?.data?.nickname || "",
            message: item?.mostRecentEntry?.data?.params?.message,
            date: getDateString(item?.mostRecentEntry?.createdAt),
            time: item?.mostRecentEntry?.createdAt
              ? DateTime.fromISO(item?.mostRecentEntry?.createdAt).toFormat(
                  "hh:mm a"
                )
              : "",
            unreadCount: item?.unreadCount || 0,
          };
        });

        setData(resData);
        setTotalCount(count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        errorToastMessage(error as Error);
      }
    };
    getData();
  }, [page, sortOrder, refreshNotifications, setPage]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());

    if (sortOrder) {
      params.set("order", sortOrder);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page, sortOrder]);

  const handleSort = (order: string) => {
    if (sortOrder === order) {
      setSortOrder("");
    } else {
      setSortOrder(order);
    }
  };

  const markAsRead = async (code: string) => {
    try {
      const url = `/notifications/chat/grouped`;
      const body = { code };
      await http.patch(url, body);

      dispatch(setRefreshNotification());
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  const handleClick = async (item: any) => {
    if (!item?.isRead) {
      await markAsRead(item?.code);
    }
    navigate(
      `/app/participants/my-patients/${item?.userId}?tab=chat&user=${item?.code}`
    );
  };

  const SortLabel = () => {
    return (
      <>
        <StyledSortLabel
          active={sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc")}
        />
        <StyledSortLabel
          active={sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc")}
        />
      </>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>User ID</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Chat</StyledTableCell>
            <StyledTableCell>
              Time
              <SortLabel />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data?.length > 0 && (
          <>
            <TableBody>
              {data?.map((item: any) => (
                <TableRow key={item?.id}>
                  <StyledTableCell
                    onClick={() => handleClick(item)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {item?.code || "-"}
                  </StyledTableCell>
                  <StyledTableCell>{item?.name || "-"}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      maxWidth: "30ch",
                      minWidth: "1px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item?.message || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      display: "flex",
                      gap: "25px",
                      alignItems: "center",
                    }}
                  >
                    {item?.date === "Today"
                      ? item?.time
                      : item?.date + " " + item?.time || "-"}

                    <Badge
                      color="error"
                      badgeContent={item?.unreadCount}
                      sx={{
                        ".MuiBadge-badge": {
                          top: "-3px",
                        },
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalCount > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalCount}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data?.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default LiveChat;
