import { Box, Typography } from "@mui/material";
import { PinkBg, PreviewContainer, StaticContentWrapper } from "./style";
import { Logo } from "./Logo";
import ChatPreview from "./ChatPreview";

const PreviewWrapper = () => {
  return (
    <Box sx={PreviewContainer}>
      <Box sx={PinkBg} />
      <Box sx={StaticContentWrapper}>
        <Box mb={3}>
          <Logo />
        </Box>
        <Typography variant="subtitle2">
          Mahalo Health Bot is a conversational chat platform allows you to add
          automated conversation to your page,your product, your service. Mahalo
          Health Bot helps attract more leads, automate your customer service
          and is a friendly face welcoming and helping your website visitors
          find what they are looking for.
        </Typography>
      </Box>
      <ChatPreview />
    </Box>
  );
};

export default PreviewWrapper;
