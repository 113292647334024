import ReviewsIcon from "@mui/icons-material/Reviews";

export const MCQIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0469 6.35134C20.2719 6.57638 20.3983 6.88155 20.3983 7.19974C20.3983 7.51794 20.2719 7.82311 20.0469 8.04814L10.4469 17.6481C10.2219 17.8731 9.91673 17.9995 9.59853 17.9995C9.28034 17.9995 8.97517 17.8731 8.75013 17.6481L3.95014 12.8481C3.73155 12.6218 3.61059 12.3187 3.61333 12.0041C3.61606 11.6894 3.74226 11.3885 3.96475 11.166C4.18724 10.9435 4.48822 10.8173 4.80285 10.8145C5.11749 10.8118 5.42061 10.9328 5.64693 11.1513L9.59853 15.1029L18.3501 6.35134C18.5752 6.12638 18.8803 6 19.1985 6C19.5167 6 19.8219 6.12638 20.0469 6.35134Z"
      fill="#111928"
      className="custom-path"
    />
  </svg>
);

export const ShortTextIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.60156 8.39922C3.60156 8.08096 3.72799 7.77573 3.95303 7.55069C4.17808 7.32565 4.4833 7.19922 4.80156 7.19922H19.2016C19.5198 7.19922 19.825 7.32565 20.0501 7.55069C20.2751 7.77573 20.4016 8.08096 20.4016 8.39922C20.4016 8.71748 20.2751 9.0227 20.0501 9.24775C19.825 9.47279 19.5198 9.59922 19.2016 9.59922H4.80156C4.4833 9.59922 4.17808 9.47279 3.95303 9.24775C3.72799 9.0227 3.60156 8.71748 3.60156 8.39922ZM3.60156 15.5992C3.60156 15.281 3.72799 14.9757 3.95303 14.7507C4.17808 14.5256 4.4833 14.3992 4.80156 14.3992H19.2016C19.5198 14.3992 19.825 14.5256 20.0501 14.7507C20.2751 14.9757 20.4016 15.281 20.4016 15.5992C20.4016 15.9175 20.2751 16.2227 20.0501 16.4477C19.825 16.6728 19.5198 16.7992 19.2016 16.7992H4.80156C4.4833 16.7992 4.17808 16.6728 3.95303 16.4477C3.72799 16.2227 3.60156 15.9175 3.60156 15.5992Z"
      fill="#111928"
      className="custom-path"
    />
  </svg>
);

export const LongTextIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.60156 6.00078C3.60156 5.68252 3.72799 5.3773 3.95303 5.15225C4.17808 4.92721 4.4833 4.80078 4.80156 4.80078H19.2016C19.5198 4.80078 19.825 4.92721 20.0501 5.15225C20.2751 5.3773 20.4016 5.68252 20.4016 6.00078C20.4016 6.31904 20.2751 6.62427 20.0501 6.84931C19.825 7.07435 19.5198 7.20078 19.2016 7.20078H4.80156C4.4833 7.20078 4.17808 7.07435 3.95303 6.84931C3.72799 6.62427 3.60156 6.31904 3.60156 6.00078ZM3.60156 12.0008C3.60156 11.6825 3.72799 11.3773 3.95303 11.1523C4.17808 10.9272 4.4833 10.8008 4.80156 10.8008H19.2016C19.5198 10.8008 19.825 10.9272 20.0501 11.1523C20.2751 11.3773 20.4016 11.6825 20.4016 12.0008C20.4016 12.319 20.2751 12.6243 20.0501 12.8493C19.825 13.0744 19.5198 13.2008 19.2016 13.2008H4.80156C4.4833 13.2008 4.17808 13.0744 3.95303 12.8493C3.72799 12.6243 3.60156 12.319 3.60156 12.0008ZM3.60156 18.0008C3.60156 17.6825 3.72799 17.3773 3.95303 17.1523C4.17808 16.9272 4.4833 16.8008 4.80156 16.8008H19.2016C19.5198 16.8008 19.825 16.9272 20.0501 17.1523C20.2751 17.3773 20.4016 17.6825 20.4016 18.0008C20.4016 18.319 20.2751 18.6243 20.0501 18.8493C19.825 19.0744 19.5198 19.2008 19.2016 19.2008H4.80156C4.4833 19.2008 4.17808 19.0744 3.95303 18.8493C3.72799 18.6243 3.60156 18.319 3.60156 18.0008Z"
      fill="#111928"
      className="custom-path"
    />
  </svg>
);

export const YesNoIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      d="M2.39844 12.6004C2.39844 12.364 2.445 12.1299 2.53545 11.9116C2.62591 11.6932 2.7585 11.4947 2.92565 11.3276C3.09279 11.1605 3.29122 11.0279 3.50961 10.9374C3.72799 10.8469 3.96206 10.8004 4.19844 10.8004C4.43482 10.8004 4.66888 10.8469 4.88727 10.9374C5.10565 11.0279 5.30408 11.1605 5.47123 11.3276C5.63838 11.4947 5.77096 11.6932 5.86142 11.9116C5.95188 12.1299 5.99844 12.364 5.99844 12.6004V19.8004C5.99844 20.2778 5.8088 20.7356 5.47123 21.0732C5.13366 21.4107 4.67583 21.6004 4.19844 21.6004C3.72105 21.6004 3.26321 21.4107 2.92565 21.0732C2.58808 20.7356 2.39844 20.2778 2.39844 19.8004V12.6004ZM7.19844 12.4V18.916C7.19823 19.362 7.3223 19.7992 7.55673 20.1786C7.79116 20.558 8.12668 20.8646 8.52564 21.064L8.58564 21.094C9.2515 21.4268 9.98564 21.6002 10.73 21.6004H17.2292C17.7843 21.6006 18.3222 21.4084 18.7515 21.0566C19.1808 20.7048 19.4748 20.2151 19.5836 19.6708L21.0236 12.4708C21.0932 12.1226 21.0847 11.7634 20.9987 11.4189C20.9127 11.0745 20.7513 10.7534 20.5262 10.4788C20.3011 10.2042 20.0179 9.98303 19.697 9.83113C19.3761 9.67924 19.0255 9.60043 18.6704 9.60039H14.3984V4.80039C14.3984 4.16387 14.1456 3.55342 13.6955 3.10333C13.2454 2.65325 12.635 2.40039 11.9984 2.40039C11.6802 2.40039 11.375 2.52682 11.1499 2.75186C10.9249 2.97691 10.7984 3.28213 10.7984 3.60039V4.40079C10.7984 5.43937 10.4616 6.44993 9.83844 7.28079L8.15844 9.51999C7.53529 10.3509 7.19844 11.3614 7.19844 12.4Z"
      fill="#111928"
      className="custom-path"
    />
  </svg>
);

export const NumberIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path d="M4 15H18H4Z" fill="#111928" className="custom-path" />
    <path
      d="M7 20L11 4M13 20L17 4M6 9H20M4 15H18"
      stroke="#111928"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="custom-stroke"
    />
  </svg>
);

export const CalendarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.19844 2.40039C6.88018 2.40039 6.57495 2.52682 6.34991 2.75186C6.12487 2.97691 5.99844 3.28213 5.99844 3.60039V4.80039H4.79844C4.16192 4.80039 3.55147 5.05325 3.10138 5.50333C2.65129 5.95342 2.39844 6.56387 2.39844 7.20039V19.2004C2.39844 19.8369 2.65129 20.4474 3.10138 20.8974C3.55147 21.3475 4.16192 21.6004 4.79844 21.6004H19.1984C19.835 21.6004 20.4454 21.3475 20.8955 20.8974C21.3456 20.4474 21.5984 19.8369 21.5984 19.2004V7.20039C21.5984 6.56387 21.3456 5.95342 20.8955 5.50333C20.4454 5.05325 19.835 4.80039 19.1984 4.80039H17.9984V3.60039C17.9984 3.28213 17.872 2.97691 17.647 2.75186C17.4219 2.52682 17.1167 2.40039 16.7984 2.40039C16.4802 2.40039 16.175 2.52682 15.9499 2.75186C15.7249 2.97691 15.5984 3.28213 15.5984 3.60039V4.80039H8.39844V3.60039C8.39844 3.28213 8.27201 2.97691 8.04697 2.75186C7.82192 2.52682 7.5167 2.40039 7.19844 2.40039ZM7.19844 8.40039C6.88018 8.40039 6.57495 8.52682 6.34991 8.75186C6.12487 8.97691 5.99844 9.28213 5.99844 9.60039C5.99844 9.91865 6.12487 10.2239 6.34991 10.4489C6.57495 10.674 6.88018 10.8004 7.19844 10.8004H16.7984C17.1167 10.8004 17.4219 10.674 17.647 10.4489C17.872 10.2239 17.9984 9.91865 17.9984 9.60039C17.9984 9.28213 17.872 8.97691 17.647 8.75186C17.4219 8.52682 17.1167 8.40039 16.7984 8.40039H7.19844Z"
      fill="#111928"
      className="custom-path"
    />
  </svg>
);

export const SliderIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      d="M2.39844 13.1996C2.39844 12.8813 2.52487 12.5761 2.74991 12.3511C2.97495 12.126 3.28018 11.9996 3.59844 11.9996H5.99844C6.3167 11.9996 6.62192 12.126 6.84697 12.3511C7.07201 12.5761 7.19844 12.8813 7.19844 13.1996V19.1996C7.19844 19.5179 7.07201 19.8231 6.84697 20.0481C6.62192 20.2732 6.3167 20.3996 5.99844 20.3996H3.59844C3.28018 20.3996 2.97495 20.2732 2.74991 20.0481C2.52487 19.8231 2.39844 19.5179 2.39844 19.1996V13.1996ZM9.59844 8.39961C9.59844 8.08135 9.72487 7.77613 9.94991 7.55108C10.175 7.32604 10.4802 7.19961 10.7984 7.19961H13.1984C13.5167 7.19961 13.8219 7.32604 14.047 7.55108C14.272 7.77613 14.3984 8.08135 14.3984 8.39961V19.1996C14.3984 19.5179 14.272 19.8231 14.047 20.0481C13.8219 20.2732 13.5167 20.3996 13.1984 20.3996H10.7984C10.4802 20.3996 10.175 20.2732 9.94991 20.0481C9.72487 19.8231 9.59844 19.5179 9.59844 19.1996V8.39961ZM16.7984 4.79961C16.7984 4.48135 16.9249 4.17612 17.1499 3.95108C17.375 3.72604 17.6802 3.59961 17.9984 3.59961H20.3984C20.7167 3.59961 21.0219 3.72604 21.247 3.95108C21.472 4.17612 21.5984 4.48135 21.5984 4.79961V19.1996C21.5984 19.5179 21.472 19.8231 21.247 20.0481C21.0219 20.2732 20.7167 20.3996 20.3984 20.3996H17.9984C17.6802 20.3996 17.375 20.2732 17.1499 20.0481C16.9249 19.8231 16.7984 19.5179 16.7984 19.1996V4.79961Z"
      fill="#111928"
      className="custom-path"
    />
  </svg>
);

export const ScheduleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="custom-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9984 21.6004C14.5445 21.6004 16.9863 20.589 18.7867 18.7886C20.587 16.9883 21.5984 14.5465 21.5984 12.0004C21.5984 9.45431 20.587 7.01251 18.7867 5.21217C16.9863 3.41182 14.5445 2.40039 11.9984 2.40039C9.45236 2.40039 7.01056 3.41182 5.21021 5.21217C3.40986 7.01251 2.39844 9.45431 2.39844 12.0004C2.39844 14.5465 3.40986 16.9883 5.21021 18.7886C7.01056 20.589 9.45236 21.6004 11.9984 21.6004ZM13.1984 7.20039C13.1984 6.88213 13.072 6.57691 12.847 6.35186C12.6219 6.12682 12.3167 6.00039 11.9984 6.00039C11.6802 6.00039 11.375 6.12682 11.1499 6.35186C10.9249 6.57691 10.7984 6.88213 10.7984 7.20039V12.0004C10.7985 12.3186 10.925 12.6238 11.15 12.8488L14.5436 16.2436C14.6551 16.3551 14.7875 16.4435 14.9332 16.5039C15.0788 16.5642 15.235 16.5953 15.3926 16.5953C15.5503 16.5953 15.7064 16.5642 15.8521 16.5039C15.9978 16.4435 16.1301 16.3551 16.2416 16.2436C16.3531 16.1321 16.4416 15.9997 16.5019 15.8541C16.5622 15.7084 16.5933 15.5523 16.5933 15.3946C16.5933 15.2369 16.5622 15.0808 16.5019 14.9351C16.4416 14.7894 16.3531 14.6571 16.2416 14.5456L13.1984 11.5036V7.20039Z"
      fill="#6B7280"
      className="custom-path"
    />
  </svg>
);

export const ImageUploadIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66797 26.668L14.3113 19.0246C14.9364 18.3997 15.7841 18.0487 16.668 18.0487C17.5518 18.0487 18.3995 18.3997 19.0246 19.0246L26.668 26.668M23.3346 23.3346L25.978 20.6913C26.6031 20.0664 27.4508 19.7153 28.3346 19.7153C29.2185 19.7153 30.0662 20.0664 30.6913 20.6913L33.3346 23.3346M23.3346 13.3346H23.3513M10.0013 33.3346H30.0013C30.8854 33.3346 31.7332 32.9834 32.3583 32.3583C32.9834 31.7332 33.3346 30.8854 33.3346 30.0013V10.0013C33.3346 9.11725 32.9834 8.2694 32.3583 7.64428C31.7332 7.01916 30.8854 6.66797 30.0013 6.66797H10.0013C9.11725 6.66797 8.2694 7.01916 7.64428 7.64428C7.01916 8.2694 6.66797 9.11725 6.66797 10.0013V30.0013C6.66797 30.8854 7.01916 31.7332 7.64428 32.3583C8.2694 32.9834 9.11725 33.3346 10.0013 33.3346Z"
      stroke="#6B7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VideoUploadIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 16.6667L32.5883 12.8733C32.8424 12.7464 33.1246 12.6865 33.4083 12.6992C33.692 12.712 33.9677 12.7971 34.2093 12.9463C34.4509 13.0956 34.6504 13.3041 34.7888 13.5521C34.9271 13.8001 34.9998 14.0793 35 14.3633V25.6367C34.9998 25.9207 34.9271 26.1999 34.7888 26.4479C34.6504 26.6959 34.4509 26.9044 34.2093 27.0537C33.9677 27.2029 33.692 27.288 33.4083 27.3008C33.1246 27.3135 32.8424 27.2536 32.5883 27.1267L25 23.3333V16.6667ZM8.33333 30H21.6667C22.5507 30 23.3986 29.6488 24.0237 29.0237C24.6488 28.3986 25 27.5507 25 26.6667V13.3333C25 12.4493 24.6488 11.6014 24.0237 10.9763C23.3986 10.3512 22.5507 10 21.6667 10H8.33333C7.44928 10 6.60143 10.3512 5.97631 10.9763C5.35119 11.6014 5 12.4493 5 13.3333V26.6667C5 27.5507 5.35119 28.3986 5.97631 29.0237C6.60143 29.6488 7.44928 30 8.33333 30Z"
      stroke="#6B7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShuffleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="9" r="2" fill="#D1D5DB" />
    <circle cx="15" cy="15" r="2" fill="#D1D5DB" />
    <circle cx="15" cy="21" r="2" fill="#D1D5DB" />
    <circle cx="15" cy="3" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="9" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="15" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="21" r="2" fill="#D1D5DB" />
    <circle cx="9" cy="3" r="2" fill="#D1D5DB" />
  </svg>
);

export const GptIcon = () => {
  return (
    <>
      <ReviewsIcon sx={{ color: "#111928" }} />
    </>
  );
};
