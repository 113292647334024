import { useState, useEffect, useMemo, useCallback } from "react";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  GridColDef,
  GridActionsCellItem,
  GridPagination,
  GridCellParams,
} from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledDataGrid,
  TablePaginationStyle,
} from "../../Common/styles/table";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  fetchAdminViewParticipantsList,
  fetchParticipantsList,
} from "../../../Redux/actions/participantsAction";
import {
  setParticipantsFilterId,
  setParticipantsPage,
  setParticipantToggle,
  setSelectedRows,
} from "../../../Redux/reducers/participantsSlice";
import { useNavigate } from "react-router";
import { Restore, Archive, Delete } from "@mui/icons-material";
import ConfirmationModal from "../Administrators/ConfirmationModal";

function CustomPagination(props: any) {
  return (
    <GridPagination
      sx={TablePaginationStyle}
      labelDisplayedRows={paginationLabel}
      {...props}
    />
  );
}

type Props = {
  adminActivePtpView?: boolean;
  hasAssignCoachAccess?: boolean;
};

const ParticipantList = ({
  adminActivePtpView = false,
  hasAssignCoachAccess = false,
}: Props) => {
  const [menuLoader, setMenuLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();
  const {
    loading,
    type,
    searchText,
    doctorsList,
    participantsData,
    totalParticipants,
    filterId,
    paginationModel,
    toggleLoader,
    selectedRows,
  } = useAppSelector((state) => state.participants);
  const { hasMasterAdminAccess, hasAdminAccess, hasHealthCoachAccess } =
    useAppSelector((state) => state.user);
  const [selectedOption, setSelectedOption] = useState(filterId);
  const [userId, setUserId] = useState("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    // if (adminActivePtpView) {
    //   dispatch(
    //     fetchAdminViewParticipantsList(
    //       paginationModel.page,
    //       hasAssignCoachAccess,
    //       filterId,
    //       searchText
    //     )
    //   );
    // } else {
    //   dispatch(
    //     fetchParticipantsList(
    //       paginationModel.page,
    //       type,
    //       filterId,
    //       searchText,
    //       hasHealthCoachAccess
    //     )
    //   );
    // }
    dispatch(
      fetchParticipantsList(
        paginationModel.page,
        type,
        filterId,
        searchText,
        hasHealthCoachAccess
      )
    );
  }, [
    paginationModel,
    type,
    filterId,
    searchText,
    dispatch,
    toggleLoader,
    adminActivePtpView,
    hasAssignCoachAccess,
    hasHealthCoachAccess,
  ]);

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleFilterItem = (id: string) => {
    setSelectedOption(id);
  };

  const handleClickOK = () => {
    setAnchorEl(null);
    if (selectedOption !== filterId) {
      dispatch(setParticipantsFilterId(selectedOption));
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setUserId("");
  };

  const modifyUser = useCallback(
    async (data: any) => {
      try {
        setMenuLoader(true);
        const newData = {
          status: data?.status === "inactive" ? "active" : "inactive",
        };
        const res: AxiosResponse = await http.put(
          `/participants/${data?.id}`,
          newData
        );
        setMenuLoader(false);
        dispatch(setParticipantToggle());
        toastMessage("success", res.data.message);
      } catch (err) {
        setMenuLoader(false);
        errorToastMessage(err as Error);
      }
    },
    [dispatch]
  );

  const refreshPage = () => {
    dispatch(setParticipantToggle());
  };
  const columns = useMemo<GridColDef<any>[]>(() => {
    const getActions = (params: any) => {
      const actionArray = [
        <GridActionsCellItem
          showInMenu
          label={params?.row?.status === "inactive" ? "Restore" : "Archive"}
          onClick={() => modifyUser(params?.row)}
          disabled={menuLoader}
          sx={{
            flexDirection: menuLoader ? "row-reverse" : "row",
          }}
          icon={
            menuLoader ? (
              <CircularProgress size={18} sx={{ mr: 1 }} />
            ) : params?.row?.status === "inactive" ? (
              <Restore fontSize="small" sx={{ mr: 1 }} />
            ) : (
              <Archive fontSize="small" sx={{ mr: 1 }} />
            )
          }
        />,
      ];

      if (hasMasterAdminAccess) {
        actionArray.push(
          <GridActionsCellItem
            showInMenu
            label={"Delete"}
            onClick={() => {
              setShowModal(true);
              setUserId(params?.row?.id);
            }}
            disabled={menuLoader}
            sx={{
              flexDirection: "row",
            }}
            icon={<Delete fontSize="small" sx={{ mr: 1 }} color="error" />}
          />
        );
      }

      return actionArray;
    };
    const temp: GridColDef<any>[] = [
      // {
      //   field: "studyNumber",
      //   headerName: "User ID",
      //   flex: 1,
      //   sortable: false,
      //   // cellClassName: "clickable-column",
      // },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        sortable: false,
        valueGetter: ({ value }) => (value ? value : "-"),
      },
      {
        field: "phoneNumber",
        headerName: "Phone number",
        flex: 1,
        sortable: false,
        valueGetter: ({ value }) => (value ? value : "-"),
      },
      // {
      //   field: "lastActive",
      //   headerName: "Last active",
      //   flex: 1,
      //   sortable: false,
      //   valueGetter: ({ value }) => (value ? value : "-"),
      // },
      // {
      //   field: "lastMeeting",
      //   headerName: "Last meeting",
      //   flex: 1,
      //   sortable: false,
      //   valueGetter: ({ value }) => (value ? value : "-"),
      // },
      // {
      //   field: "nextMeeting",
      //   headerName: "Next meeting",
      //   flex: 1,
      //   sortable: false,
      //   valueGetter: ({ value }) => (value ? value : "-"),
      // },
      // {
      //   field: "doctor",
      //   headerName: "Health coach",
      //   flex: 1,
      //   sortable: false,
      //   renderCell: ({ value }: any) => (
      //     <Typography sx={{ textTransform: "capitalize" }}>{value}</Typography>
      //   ),
      // },
      {
        field: "createdAt",
        headerName: "Onboarding date",
        flex: 1,
        sortable: false,
      },
      // {
      //   field: "id",
      //   type: "actions",
      //   flex: 1,
      //   headerAlign: "right",
      //   renderHeader: !hasAssignCoachAccess
      //     ? () => (
      //         <IconButton onClick={openFilterMenu}>
      //           <FilterAltIcon color={filterId ? "primary" : "disabled"} />
      //         </IconButton>
      //       )
      //     : undefined,
      //   align: "right",
      //   getActions: getActions,
      // },
    ];
    return temp;
  }, [
    // filterId,
    hasMasterAdminAccess,
    menuLoader,
    modifyUser,
    // hasAssignCoachAccess,
  ]);

  // const handleCellClick = (params: GridCellParams) => {
  //   const { field, row } = params;

  //   if (field === "studyNumber" && type !== "unregistered") {
  //     // sessionStorage.clear();
  //     if (hasHealthCoachAccess) {
  //       navigate(
  //         `/app/participants/my-patients/${row?.id}?tab=profile&user=${row?.studyNumber}`
  //       );
  //     } else {
  //       navigate(`/app/participants/${row?.id}`, {
  //         state: { user: row?.studyNumber },
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <StyledDataGrid
        rows={loading ? [] : participantsData}
        loading={loading}
        slots={{
          loadingOverlay: () => <LinearProgress />,
          pagination:
            totalParticipants < pageSize ? () => <></> : CustomPagination,
          noRowsOverlay: () => (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          ),
        }}
        columns={columns}
        // columnVisibilityModel={{
        //   // type: hasAdminAccess && type !== "unregistered",
        //   id: hasAdminAccess && type !== "unregistered",
        //   doctor:
        //     !hasAssignCoachAccess &&
        //     !hasHealthCoachAccess &&
        //     type !== "unregistered",
        //   onboardingDate: !hasHealthCoachAccess && type !== "unregistered",
        //   name: hasHealthCoachAccess,
        //   lastActive: hasHealthCoachAccess,
        //   lastMeeting: hasHealthCoachAccess,
        //   nextMeeting: hasHealthCoachAccess,
        // }}
        // onCellClick={handleCellClick}
        keepNonExistentRowsSelected
        disableRowSelectionOnClick
        checkboxSelection={false}
        onRowSelectionModelChange={(newRowSelectionModel: any) => {
          dispatch(setSelectedRows(newRowSelectionModel));
        }}
        rowSelectionModel={selectedRows}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(model) =>
          dispatch(setParticipantsPage(model))
        }
        pageSizeOptions={[pageSize]}
        rowCount={totalParticipants}
        disableColumnMenu
        autoHeight
        rowHeight={65}
      />
      {type !== "unregistered" && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClickOK}
          PaperProps={{
            style: {
              maxHeight: "400px",
              minWidth: "20ch",
            },
          }}
        >
          {doctorsList?.map((item: any) => (
            <MenuItem
              selected={item?.value === selectedOption}
              key={item?.value}
              onClick={() => handleFilterItem(item?.value)}
            >
              <ListItemIcon>
                {item?.value === selectedOption ? (
                  <RadioButtonCheckedIcon fontSize="small" color="primary" />
                ) : (
                  <RadioButtonUncheckedIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText sx={{ textTransform: "capitalize" }}>
                {item?.text}
              </ListItemText>
            </MenuItem>
          ))}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              position: "sticky",
              bottom: 0,
              bgcolor: "#fff",
              p: 1,
            }}
          >
            <Button variant="text" onClick={() => handleFilterItem("")}>
              Reset
            </Button>
            <Button variant="contained" onClick={handleClickOK}>
              Ok
            </Button>
          </Box>
        </Menu>
      )}
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          closeModal={closeModal}
          userId={userId}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default ParticipantList;
