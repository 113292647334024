import { Box, Button, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";

const schema = yup.object().shape({
  accessToken: yup.string().required("Required"),
  webhookToken: yup.string().required("Required"),
  phoneNumberId: yup.string().required("Required"),
});

const ChatbotIntegrate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const getWebhookUrl = async () => {
      try {
        // const res = await axios.get(`/webhook/${id}`);
        const res = await http.get(`/webhook/bot/${id}`);
        const resData = res?.data?.data;
        setData(resData);
      } catch (error) {
        console.log(error);
      }
    };
    getWebhookUrl();
  }, [id]);

  const submitData = async (values: any) => {
    const body = {
      accessToken: values?.accessToken,
      webhookToken: values?.webhookToken,
      phoneNumberId: values?.phoneNumberId,
      webhookUrl: values?.webhookUrl,
    };
    try {
      const res = await http.patch(`/webhook/bot/${id}`, body);
      setData(res?.data?.data);
      toastMessage("success", res?.data?.message);
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h3"> Integrate to Whatsapp</Typography>

      <Formik
        initialValues={{
          accessToken: data?.accessToken || "",
          webhookToken: data?.webhookToken || "",
          phoneNumberId: data?.phoneNumberId || "",
          webhookUrl: data?.webhookUrl || "",
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values) => {
          submitData(values);
        }}
      >
        {({ errors, touched, getFieldProps, values }: any) => (
          <Form>
            <Box>
              <Typography>WebHook URL</Typography>
              <TextField
                {...getFieldProps("webhookUrl")}
                sx={{ cursor: "pointer" }}
              />
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(values?.webhookUrl);
                }}
              >
                Copy
              </Button>
            </Box>
            <Box>
              <Typography>Verify webhook Token</Typography>
              <TextField
                id="webhookToken"
                {...getFieldProps("webhookToken")}
                // value={data?.webhookToken}
                error={
                  touched?.webhookToken && errors?.webhookToken ? true : false
                }
                helperText={
                  touched?.webhookToken && errors?.webhookToken
                    ? errors?.webhookToken
                    : " "
                }
              />
            </Box>
            <Box>
              <Typography>Access Token</Typography>
              <TextField
                {...getFieldProps("accessToken")}
                error={
                  touched?.accessToken && errors?.accessToken ? true : false
                }
                helperText={
                  touched?.accessToken && errors?.accessToken
                    ? errors?.accessToken
                    : " "
                }
              />
            </Box>
            <Box>
              <Typography>Phone number ID</Typography>
              <TextField
                {...getFieldProps("phoneNumberId")}
                error={
                  touched?.phoneNumberId && errors?.phoneNumberId ? true : false
                }
                helperText={
                  touched?.phoneNumberId && errors?.phoneNumberId
                    ? errors?.phoneNumberId
                    : " "
                }
              />
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button variant="contained" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ChatbotIntegrate;
