import { useMemo, useState } from "react";
import { debounce } from "lodash";
import { Box, Button, TextField, Typography } from "@mui/material";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setParticipantsSearchText } from "../../../Redux/reducers/participantsSlice";
import AddParticipantModal from "./AddParticipantModal";

const ParticipantHeader = () => {
  const dispatch = useAppDispatch();
  // const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { searchText, type, activePtpType } = useAppSelector(
    (state) => state.participants
  );
  const { hasAdminAccess } = useAppSelector((state) => state.user);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setParticipantsSearchText(val));
      }, 500),
    [dispatch]
  );

  // const uploadStudyList = async (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     const isLt5M = file.size / 1024 / 1024 < 5;
  //     if (!isLt5M) {
  //       toastMessage("warning", "File size must smaller than 5MB!");
  //       return false;
  //     }
  //     try {
  //       setLoader(true);
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       await http.post("/participants/import-study-numbers", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       toastMessage("success", "Study numbers added successfully");
  //       if (type === "unregistered") {
  //         dispatch(setParticipantToggle());
  //       }
  //       setLoader(false);
  //     } catch (err) {
  //       errorToastMessage(err as Error);
  //       setLoader(false);
  //     }
  //   }
  // };

  // const reassignDoctor = () => {
  //   setShowModal(true);
  // };

  const closeModal = () => {
    setShowModal(false);
  };

  // const downloadSample = () => {
  //   downloadStudyNumber();
  // };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Participants
        </Typography>

        {/* <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder="Search for user ID"
          defaultValue={searchText}
          key={hasAdminAccess && type === "active" ? activePtpType : type}
        /> */}
      </Box>

      <Box sx={HeaderRightContent}>
        {/* {loader ? (
          <CircularProgress size={25} />
        ) : (
          <>
            {selectedRows?.length > 0 &&
              (hasAdminAccess || hasClinicalAssistantAccess) && (
                <Button
                  variant="outlined"
                  component="label"
                  onClick={reassignDoctor}
                >
                  Assign health coach
                </Button>
              )}
            {hasAdminAccess && (
              <>
                <Button variant="contained" onClick={downloadSample}>
                  Download sample CSV
                </Button>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<UploadIcon />}
                >
                  Upload study numbers
                  <input
                    hidden
                    accept="text/csv"
                    type="file"
                    onChange={uploadStudyList}
                  />
                </Button>
              </>
            )}
          </>
        )} */}
        <Button variant="contained" onClick={() => setShowModal(true)}>
          Add Participant
        </Button>
      </Box>

      {showModal && (
        // <AssignDoctorModal
        //   showModal={showModal}
        //   closeModal={closeModal}
        //   selectedRows={selectedRows}
        // />
        <AddParticipantModal showModal={showModal} closeModal={closeModal} />
      )}
    </StyledHeader>
  );
};

export default ParticipantHeader;
