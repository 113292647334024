import { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  StyledTableCell,
  NoDataContainer,
  StyledSortLabel,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
} from "../../../../Common/styles/table";
import { errorToastMessage } from "../../../../../utils/toast";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  setLogsFilter,
  setLogsPage,
  setSortColumn,
  setSortOrder,
} from "../../../../../Redux/reducers/myPatientsSlice";
import { AxiosResponse } from "axios";
import { getTimeString } from "../../../../../utils/time";
import {
  FilterAlt,
  PhotoSizeSelectActualOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";

const LogsTabPanel = () => {
  const {
    logsPage: page,
    logsStartDate,
    logsEndDate,
    logsType: type,
    logsFilter,
    sortOrder,
    sortColumn,
  } = useAppSelector((state) => state.myPatients);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({ headers: [], rows: [] });
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(logsFilter);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);

  const openFilter = Boolean(filterEl);

  const { id } = useParams();

  const handleChangePage = (_: unknown, newPage: number) => {
    dispatch(setLogsPage(newPage));
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setSortOrder(""));
      dispatch(setSortColumn(""));
    } else {
      dispatch(setSortOrder(order));
      dispatch(setSortColumn(column));
    }
  };

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFilterEl(event?.currentTarget);
  };

  const handleFilterItem = (value: string) => {
    setSelectedOption(value);
  };
  const closeFilterMenu = () => {
    setFilterEl(null);
    if (selectedOption !== logsFilter) {
      dispatch(setLogsFilter(selectedOption));
    }
  };
  const clearFilterMenu = () => {
    dispatch(setLogsFilter(""));
    setSelectedOption("");
    setFilterEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const startDate = DateTime?.fromISO(logsStartDate)
          ?.startOf("day")
          .toUTC()
          .toISO();
        const endDate = DateTime?.fromISO(logsEndDate)
          ?.endOf("day")
          ?.toUTC()
          .toISO();
        let res: AxiosResponse;
        let url = "";
        if (type === "medication") {
          url = `/activity/medications-status?page=${
            page + 1
          }&size=${pageSize}&userId=${id}&isStatin=false&startDate=${startDate}&endDate=${endDate}`;

          if (sortColumn) url += `&sortBy=${sortColumn}`;
          if (logsFilter) url += `&status=${logsFilter}`;
        } else {
          url = `/activity/participant/${type}?userId=${id}&&page=${
            page + 1
          }&size=${pageSize}&startDate=${startDate}&endDate=${endDate}`;
        }
        if (sortOrder) url += `&order=${sortOrder}`;
        res = await http.get(url);
        const resData: any = res?.data?.data?.rows || [];
        let formattedData: any = {
          headers: [],
          rows: [],
        };
        if (type === "food") {
          formattedData = {
            headers: [
              {
                label: "Name",
                key: "name",
              },
              {
                label: "Image",
                key: "image",
              },
              {
                label: "Meal size",
                key: "mealSize",
              },
              {
                label: "Healthiness",
                key: "healthiness",
              },
              {
                label: "Notes",
                key: "notes",
              },
              {
                label: "Date",
                key: "date",
                sort: true,
              },
            ],
            rows: resData?.map((item: any) => {
              return {
                name: item?.name || "",
                image: item?.url || "",
                mealSize: item?.mealSize || "",
                healthiness: item?.healthiness || "",
                notes: item?.notes || "",
                date: item?.takenOn
                  ? DateTime.fromISO(item?.takenOn).toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : "",
              };
            }),
          };
        } else if (type === "sleep") {
          formattedData = {
            headers: [
              {
                label: "Hours",
                key: "hours",
              },
              {
                label: "Date",
                key: "date",
                sort: true,
              },
            ],
            rows: resData?.map((item: any) => {
              return {
                hours: getTimeString(item?.duration || 0),
                date: item?.trackDate
                  ? DateTime.fromISO(item?.trackDate).toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : "",
              };
            }),
          };
        } else if (type === "step") {
          formattedData = {
            headers: [
              {
                label: "Steps",
                key: "steps",
              },
              {
                label: "Date",
                key: "date",
                sort: true,
              },
            ],
            rows: resData?.map((item: any) => {
              return {
                steps: item?.steps || "",
                date: item?.trackDate
                  ? DateTime.fromISO(item?.trackDate).toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : "",
              };
            }),
          };
        } else {
          formattedData = {
            headers: [
              {
                label: "Date",
                key: "trackDate",
                sort: true,
              },
              {
                label: "Medication name",
                key: "name",
                sort: true,
              },
              {
                label: "Status",
                key: "status",
                filter: true,
              },
            ],
            rows: resData?.map((item: any) => {
              return {
                trackDate: item?.trackDate
                  ? DateTime.fromISO(item?.trackDate).toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : "",
                name: item?.medication?.name,
                status: item?.isMissed
                  ? "Missed"
                  : item?.isSkipped
                  ? "Skipped"
                  : "Taken",
              };
            }),
          };
        }
        setData(formattedData);
        setTotalCount(res?.data?.data?.count || 0);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setData({ headers: [], rows: [] });
        setTotalCount(0);
        setLoading(false);
      }
    };

    fetchData();
  }, [
    setLoading,
    type,
    logsStartDate,
    logsEndDate,
    logsFilter,
    page,
    id,
    sortOrder,
    sortColumn,
  ]);

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  console.log({ totalCount });

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {!loading ? (
              data?.headers?.map(
                (hdr: {
                  label: string;
                  key: string;
                  sort?: boolean;
                  filter?: boolean;
                }) => (
                  <StyledTableCell key={hdr?.key}>
                    {hdr?.label}
                    {hdr?.sort && <SortLabel column={hdr?.key} />}
                    {hdr?.filter && (
                      <IconButton onClick={openFilterMenu}>
                        <FilterAlt
                          color={logsFilter ? "primary" : "disabled"}
                        />
                      </IconButton>
                    )}
                  </StyledTableCell>
                )
              )
            ) : (
              <StyledTableCell />
            )}
          </TableRow>
        </TableHead>
        {!loading && data?.rows?.length > 0 && (
          <>
            <TableBody>
              {data?.rows?.map?.((row: any, index: number) => (
                <TableRow key={index}>
                  {data?.headers?.map((hdr: { label: string; key: string }) => (
                    <StyledTableCell
                      key={hdr?.key}
                      sx={{
                        color:
                          hdr?.key === "status"
                            ? row[hdr?.key] === "Missed"
                              ? "#6C727F"
                              : row[hdr?.key] === "Skipped"
                              ? "#F05252"
                              : "#29B355"
                            : "#111928",
                      }}
                    >
                      {hdr?.key === "image" ? (
                        <Avatar
                          variant="rounded"
                          src={row[hdr?.key]}
                          sx={{
                            width: 50,
                            height: 50,
                            fontSize: 14,
                            bgcolor: "lightgray",
                            color: "#000",
                          }}
                        >
                          <PhotoSizeSelectActualOutlined />
                        </Avatar>
                      ) : (
                        row[hdr?.key] || "-"
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalCount > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalCount}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data?.rows?.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        {["taken", "skipped", "missed"]?.map((item: any) => (
          <MenuItem
            selected={item === selectedOption}
            key={item}
            onClick={() => handleFilterItem(item)}
          >
            <ListItemIcon>
              {item === selectedOption ? (
                <RadioButtonChecked fontSize="small" color="primary" />
              ) : (
                <RadioButtonUnchecked fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearFilterMenu}>
            Reset
          </Button>
          <Button variant="contained" onClick={closeFilterMenu}>
            Ok
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default LogsTabPanel;
