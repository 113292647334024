import { Navigate } from "react-router-dom";
import { useAppSelector } from "../Redux/hooks";

type Props = {
  children?: JSX.Element | JSX.Element[];
  //Pass any array of roles that have access to the route
  disallowedRoles?: string[];
  allowedRoles?: string[];
};

export const AuthenticatedRoute: React.FC<Props> = ({
  children,
  allowedRoles,
  disallowedRoles,
}) => {
  const { isUserAuthenticated, role, hasHealthCoachAccess } = useAppSelector(
    (state) => state.user
  );

  if (!isUserAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (disallowedRoles && disallowedRoles.includes(role)) {
    // return hasHealthCoachAccess ? (
    //   <Navigate to="/app/dashboard" />
    // ) : (
    //   <Navigate to="/app/participants" />
    // );
    return <Navigate to="/app/participants" />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    // return hasHealthCoachAccess ? (
    //   <Navigate to="/app/dashboard" />
    // ) : (
    //   <Navigate to="/app/participants" />
    // );
    return <Navigate to="/app/participants" />;
  }

  return <>{children}</>;
};

export const UnAuthenticatedRoute: React.FC<any> = ({ children }) => {
  const { isUserAuthenticated, hasHealthCoachAccess } = useAppSelector(
    (state) => state.user
  );

  return isUserAuthenticated ? (
    hasHealthCoachAccess ? (
      <Navigate to="/app/participants" />
    ) : (
      <Navigate to="/app/participants" />
    )
  ) : (
    <>{children}</>
  );
};
