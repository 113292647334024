import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import ChangePassword from "./Components/Auth/ChangePassword";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import NotificationCenter from "./Components/NotificationCenter/V2/NotificationCenter";
// import PasswordRequest from "./Components/Auth/PasswordRequest";
import ResetPassword from "./Components/Auth/ResetPassword";
import SetPassword from "./Components/Auth/SetPassword";
import SignIn from "./Components/Auth/SignIn";
import VerifyEmail from "./Components/Auth/VerifyEmail";
import AppLayout from "./Components/Layout/AppLayout";
import Administrators from "./Components/UserManagement/Administrators/Administrators";
import Participants from "./Components/UserManagement/Participants/Participants";
import { UnAuthenticatedRoute, AuthenticatedRoute } from "./utils/Route";
import Chatbot from "./Components/Chatbot/Chatbot";
import BrokenLink from "./Components/Chatbot/BrokenLink/BrokenLink";
import OnboardingBuilder from "./Components/Onboarding/Builder/Builder";
import CMS from "./Components/CMS/CMS";
import LearningModuleDetails from "./Components/CMS/Modules/LearningModule/LearningModuleDetails";
import Units from "./Components/CMS/Modules/EducationModule.tsx/Units";
import Pages from "./Components/CMS/Modules/EducationModule.tsx/Pages";
import Reviews from "./Components/CMS/Modules/EducationModule.tsx/Feedback/Reviews";
import Cloud from "./Components/Cloud/Cloud";
import ChatbotBuilder from "./Components/Chatbot/Builder/ChatbotBuilder";
import CMSPreviewWrapper from "./Components/CMS/Preview/CMSPreviewWrapper";
import CMSBuilder from "./Components/CMS/Builder/CMSBuilder";
import WebviewWrapper from "./Components/CMS/Webview/WebviewWrapper";
import Journal from "./Components/PatientDetails/FoodJournal/Journal";
import PreviewWrapper from "./Components/Chatbot/Preview/PreviewWrapper";
import Settings from "./Components/Settings/Settings";
import LearningPath from "./Components/LearningPath/LearningPath";
import Activities from "./Components/Activities/V2/Activities";
import ReflectionJournal from "./Components/UserManagement/Participants/ReflectionJournal";
import ParticipantDetails from "./Components/UserManagement/Participants/ParticipantDetails";
import PreviewPrivacyPolicy from "./Components/Settings/PreviewPrivacyPolicy";
import DeleteAccountSteps from "./Components/Settings/DeleteAccountSteps";
import GoogleFit from "./Components/Settings/GoogleFit/GoogleFit";
import MobileSettings from "./Components/Settings/MobileSettings/MobileSettings";
import ParticipantDetailsTab from "./Components/UserManagement/Participants/HealthCoachView/ParticipantDetailsTab";
import Schedule from "./Components/Schedule/Schedule";
import MyCalendar from "./Components/MyCalendar/MyCalendar";
import SelfReflection from "./Components/SelfReflection/SelfReflection";
import Availability from "./Components/Schedule/Availability";
import AssignSlots from "./Components/Schedule/AssignSlots";
import ReflectionBuilder from "./Components/SelfReflection/Builder/ReflectionBuilder";
import ReflectionPreviewWrapper from "./Components/SelfReflection/Preview/ReflectionPreviewWrapper";
import CoachProfile from "./Components/Profile/CoachProfile";
import OpenTok from "./Components/UserManagement/Participants/HealthCoachView/Profile/OpenTok/OpenTok";
import CoachNotifications from "./Components/NotificationCenter/CoachNotifications";
import Support from "./Components/Settings/Support/Support";
import Dashboard from "./Components/Dashboard/Dashboard";
import { useAppSelector } from "./Redux/hooks";
import ChatbotIntegrate from "./Components/Chatbot/ChatbotIntegrate";
// import AssistantAssignSlots from "./Components/Schedule/AssistantAssignSlots";
// import Reschedule from "./Components/MyCalendar/Reschedule";

// const DefaultRoute = () => {
//   const { hasHealthCoachAccess } = useAppSelector((state) => state.user);

//   return hasHealthCoachAccess ? (
//     <Navigate to="/app/dashboard" replace />
//   ) : (
//     <Navigate to="/app/chatbot" replace />
//   );
// };

const routes = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <UnAuthenticatedRoute>
        <Outlet />
      </UnAuthenticatedRoute>
    ),
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "set-password",
        element: <SetPassword />,
      },
      {
        path: "verify-email",
        element: <VerifyEmail />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <AuthenticatedRoute>
        <AppLayout />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <AuthenticatedRoute allowedRoles={["coach"]}>
            <Dashboard />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "change-password",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin", "coach"]}>
            <ChangePassword />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "administrators",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <Administrators />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "coach-notification",
        element: (
          <AuthenticatedRoute allowedRoles={["coach"]}>
            <CoachNotifications />
          </AuthenticatedRoute>
        ),
      },
      // {
      //   path: "assign-slots",
      //   element: (
      //     <AuthenticatedRoute allowedRoles={["clinical_assistant"]}>
      //       <AssistantAssignSlots />
      //     </AuthenticatedRoute>
      //   ),
      // },
      {
        path: "participants",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute
              // allowedRoles={["master_admin", "admin", "coach"]}
              >
                <Participants />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <AuthenticatedRoute
                allowedRoles={["master_admin", "admin", "clinical_assistant"]}
              >
                <ParticipantDetails />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "my-patients/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <ParticipantDetailsTab />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/journal",
            element: (
              <AuthenticatedRoute
                allowedRoles={["master_admin", "admin", "coach"]}
              >
                <Journal />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/self-reflection",
            element: (
              <AuthenticatedRoute
                allowedRoles={["master_admin", "admin", "coach"]}
              >
                <ReflectionJournal />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      // {
      //   path: "password-request",
      //   element: (
      //     <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
      //       <PasswordRequest />
      //     </AuthenticatedRoute>
      //   ),
      // },
      {
        path: "onboarding",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <OnboardingBuilder />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "notification",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin", "coach"]}>
            <NotificationCenter />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "daily-activities",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin", "coach"]}>
            <Activities />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "chatbot",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute
              // allowedRoles={["master_admin", "admin"]}
              >
                <Chatbot />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "broken-link",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <BrokenLink />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <ChatbotBuilder />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "integrate/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <ChatbotIntegrate />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "cms",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <CMS />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "learning/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <LearningModuleDetails />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Units />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "units/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Pages />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "review/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Reviews />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <CMSBuilder />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "preview/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <CMSPreviewWrapper />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "tags-highlights",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <Cloud />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin"]}>
            <Settings />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "learning-path",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <LearningPath />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "mobile-settings",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin"]}>
            <MobileSettings />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "schedule",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <Schedule />
              </AuthenticatedRoute>
            ),
          },
        ],
      },

      {
        path: "my-calendar",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <MyCalendar />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "my-availability",
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <Availability />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "assign-slots",
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <AssignSlots />
              </AuthenticatedRoute>
            ),
          },
          // {
          //   path: "reschedule/:id",
          //   element: (
          //     <AuthenticatedRoute allowedRoles={["coach"]}>
          //       <Reschedule />
          //     </AuthenticatedRoute>
          //   ),
          // },
        ],
      },

      {
        path: "self-reflection-exercises",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <SelfReflection />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <ReflectionBuilder />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "preview",
            element: (
              <AuthenticatedRoute allowedRoles={["coach"]}>
                <ReflectionPreviewWrapper />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "profile",
        element: (
          <AuthenticatedRoute allowedRoles={["coach", "clinical_assistant"]}>
            <CoachProfile />
          </AuthenticatedRoute>
        ),
      },
    ],
  },
  {
    path: "consultation/:id",
    element: (
      <AuthenticatedRoute allowedRoles={["coach"]}>
        <OpenTok />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/lesson/read/:id",
    element: <WebviewWrapper />,
  },
  {
    path: "chatbot/preview/:id",
    element: (
      <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
        <PreviewWrapper />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/privacy-policy/:lang",
    element: <PreviewPrivacyPolicy />,
  },
  {
    path: "/privacy-policy",
    element: <PreviewPrivacyPolicy />,
  },
  {
    path: "/delete-steps",
    element: <DeleteAccountSteps />,
  },
  {
    path: "/support/:lang",
    element: <Support />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/home",
    element: <GoogleFit />,
  },
  {
    path: "/",
    element: <Navigate to="/app/participants" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/app/participants" replace />,
  },
]);

export default routes;
