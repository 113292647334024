import { DateTime } from "luxon";

export const AccMap = [
  { label: "Onboarding", value: "onboarding" },
  { label: "Quiz", value: "quiz" },
  { label: "Poll", value: "poll" },
  { label: "FAQ", value: "faq" },
  { label: "AOC", value: "aoc" },
  { label: "Reflection journal", value: "journal" },
  { label: "Search", value: "search" },
];

export const QuizMap = [
  { label: "Single best answer", value: "single_select" },
  { label: "Multiple choice quiz", value: "multi_select" },
  { label: "Text", value: "text" },
  { label: "Slider", value: "slider" },
];

export const SearchMap = [
  { label: "Found", value: "found" },
  { label: "Not found", value: "notFound" },
];

export const calculateIsCorrect = (data: any): boolean => {
  let isCorrect = false;
  if (data?.type === "single_select") {
    const selectedAnswer = data?.question_choice_ids
      ? data?.question_choice_ids[0]
      : "";
    const correctAnswer = (data?.choices || [])?.find(
      (item: any) => item?.isCorrect
    );
    if (selectedAnswer === correctAnswer?.id) isCorrect = true;
  }
  if (data?.type === "multi_select") {
    const selectedAnswers = data?.question_choice_ids
      ? data?.question_choice_ids
      : [];
    const correctAnswers = (data?.choices || [])
      ?.filter((item: any) => item?.isCorrect)
      ?.map((q: any) => q?.id);
    const allCorrectAnswersIncluded = correctAnswers?.every(
      (correctAnswer: any) => selectedAnswers?.includes(correctAnswer)
    );
    if (allCorrectAnswersIncluded) isCorrect = true;
  }

  return isCorrect;
};

export const getQuizData = (data: any) => {
  const groupedItems = data.reduce((groups: any, item: any) => {
    if (!groups[item.type]) {
      groups[item.type] = [];
    }
    groups[item.type].push({
      title: item?.name,
      question: item?.title,
      answer: ["slider", "text"].includes(item?.type)
        ? item?.text_value || ""
        : item?.question_choice_ids
        ? item?.question_choice_ids
            ?.map((id: string) => {
              const qtnChoice = item?.choices?.find((ch: any) => ch.id === id);

              return qtnChoice?.label;
            })
            ?.join(", ")
        : "",
      isCorrect: ["single_select", "multi_select"].includes(item?.type)
        ? calculateIsCorrect(item)
        : false,
      // choiceIds: item?.question_choice_ids
      //   ? item?.question_choice_ids
      //       ?.map((id: string) => {
      //         const qtnChoice = item?.choices?.find(
      //           (ch: any) => ch.id === id
      //         );

      //         return qtnChoice?.label;
      //       })
      //       ?.join(", ")
      //   : "",
      // questionChoiceIds: item?.choices
      //   ?.map((choice: any) => choice?.label)
      //   ?.join(", "),
      date: DateTime?.fromISO(item?.created_at)?.isValid
        ? DateTime?.fromISO(item?.created_at)?.toFormat("dd/LL/yyyy hh:mm a")
        : "",
      dateTime: item?.created_at,
    });
    return groups;
  }, {});

  // Sort items within each group by created_at
  for (const type in groupedItems) {
    groupedItems[type].sort((a: any, b: any) => {
      const prevDate: any = new Date(a?.dateTime);
      const nextDate: any = new Date(b?.dateTime);
      return nextDate - prevDate;
    });
  }

  return groupedItems;
};

export const getSearchData = (data: any) => {
  const groupedItems = data.reduce(
    (result: any, item: any) => {
      if (item.hits === 0) {
        result.notFound.push({
          title: item?.keyword,
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
          dateTime: item?.created_at,
        });
      } else {
        result.found.push({
          title: item?.keyword,
          hits: item?.hits,
          date: DateTime?.fromISO(item?.created_at)?.isValid
            ? DateTime?.fromISO(item?.created_at)?.toFormat(
                "dd/LL/yyyy hh:mm a"
              )
            : "",
          dateTime: item?.created_at,
        });
      }
      return result;
    },
    { found: [], notFound: [] }
  );

  // Sort items within each group by created_at
  for (const type in groupedItems) {
    groupedItems[type].sort((a: any, b: any) => {
      const prevDate: any = new Date(a?.dateTime);
      const nextDate: any = new Date(b?.dateTime);
      return nextDate - prevDate;
    });
  }

  return groupedItems;
};
