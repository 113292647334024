import { useEffect, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import {
  TablePaginationStyle,
  StyledTableCell,
  paginationLabel,
  pageSize,
  NoDataContainer,
  StyledSortLabel,
} from "../../../../Common/styles/table";
import { errorToastMessage } from "../../../../../utils/toast";
import { FilterAlt } from "@mui/icons-material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  setJournalFilter,
  setJournalPage,
  setSortColumn,
  setSortOrder,
} from "../../../../../Redux/reducers/myPatientsSlice";
import PatientSelfReflectionModal from "./PatientSelfReflectionModal";

const PatientSelfReflection = () => {
  const { journalPage, journalFilter, sortOrder, sortColumn } = useAppSelector(
    (state) => state.myPatients
  );
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  // const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // const [filterId, setFilterId] = useState(
  //   sessionStorage.getItem("journal-reflection-filter") || ""
  // );
  // const [selectedOption, setSelectedOption] = useState(filterId);
  const [selectedOption, setSelectedOption] = useState(journalFilter);

  // const [sortOrder, setSortOrder] = useState("");
  // const [sortColumn, setSortColumn] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const { id } = useParams();
  const openFilter = Boolean(filterEl);

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFilterEl(event?.currentTarget);
  };

  const handleFilterItem = (value: string) => {
    setSelectedOption(value);
  };
  const closeFilterMenu = () => {
    setFilterEl(null);
    if (selectedOption !== journalFilter) {
      // setFilterId(selectedOption);
      dispatch(setJournalFilter(selectedOption));
      // sessionStorage.setItem("journal-reflection-filter", selectedOption);
    }
  };
  const clearFilterMenu = () => {
    // setFilterId("");
    dispatch(setJournalFilter(""));
    // sessionStorage.setItem("journal-reflection-filter", "");
    setSelectedOption("");
    setFilterEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let url = `/self-reflection-assignments/journal?type=self_reflection&participantId=${id}&page=${
          journalPage + 1
        }&size=${pageSize}`;

        if (sortOrder && sortColumn) {
          url += `&order=${sortOrder}&sortBy=${sortColumn}`;
        }

        if (journalFilter !== "") {
          url = url + `&status=${journalFilter}`;
        }
        const res = await http.get(url);

        const newData = res.data.data.rows.map((item: any) => {
          return {
            id: item?.id,
            title: item?.selfReflectionExercise?.serialNumber,
            userTitle: item?.selfReflectionExercise?.title,
            selfReflectionId: item?.selfReflectionExerciseId,
            assignDate: item?.scheduledDate
              ? DateTime.fromISO(item.scheduledDate).toFormat("dd LLL yyyy")
              : "",
            dueDate: item?.dueDate
              ? DateTime.fromISO(item?.dueDate).toFormat("dd LLL yyyy")
              : "",
            completionDate: item?.completedAt
              ? DateTime.fromISO(item?.completedAt).toFormat("dd LLL yyyy")
              : item?.status === "completed" && item?.updatedAt
              ? DateTime.fromISO(item?.updatedAt).toFormat("dd LLL yyyy")
              : "",
            status: item?.status,
          };
        });
        setData(newData);
        setLoading(false);
        setTotalCount(newData.length);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };

    fetchData();
  }, [
    setLoading,
    setData,
    setTotalCount,
    journalFilter,
    id,
    journalPage,
    sortOrder,
    sortColumn,
  ]);

  const handleChangePage = (_: unknown, newPage: number) => {
    dispatch(setJournalPage(newPage));
    // setPage(newPage);
  };

  const handleCellClick = (row: any) => {
    setShowModal(true);
    setRowData(row);
  };

  const closeModal = () => {
    setShowModal(false);
    setRowData(null);
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setSortOrder(""));
      dispatch(setSortColumn(""));
    } else {
      dispatch(setSortOrder(order));
      dispatch(setSortColumn(column));
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {/* <StyledTableCell>Sl.No.</StyledTableCell> */}
            <StyledTableCell>Title</StyledTableCell>
            {/* <StyledTableCell>Description</StyledTableCell> */}
            <StyledTableCell>
              Assign date
              <SortLabel column="scheduledDate" />
            </StyledTableCell>
            <StyledTableCell>
              Due date
              <SortLabel column="dueDate" />
            </StyledTableCell>
            <StyledTableCell>
              Status
              <IconButton onClick={openFilterMenu}>
                <FilterAlt color={journalFilter ? "primary" : "disabled"} />
              </IconButton>
            </StyledTableCell>
            <StyledTableCell>
              Completion date
              <SortLabel column="updatedAt" />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data.length > 0 && (
          <>
            <TableBody>
              {data.map((item: any) => (
                <TableRow key={item.id}>
                  {/* <StyledTableCell>{item.title || "-"}</StyledTableCell> */}
                  <StyledTableCell
                    onClick={() => handleCellClick(item)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {item?.userTitle || "-"}
                  </StyledTableCell>
                  {/* <StyledTableCell>{item.description || "-"}</StyledTableCell> */}
                  <StyledTableCell>{item?.assignDate || "-"}</StyledTableCell>
                  <StyledTableCell>{item?.dueDate || "-"}</StyledTableCell>
                  <StyledTableCell
                    sx={
                      item.status === "completed"
                        ? { color: "#29B355", textTransform: "capitalize" }
                        : { color: "#FFC20A", textTransform: "capitalize" }
                    }
                  >
                    {item?.status || "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item?.completionDate || "-"}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalCount > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalCount}
                    page={journalPage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        {["completed", "pending"]?.map((item: any) => (
          <MenuItem
            selected={item === selectedOption}
            key={item}
            onClick={() => handleFilterItem(item)}
          >
            <ListItemIcon>
              {item === selectedOption ? (
                <RadioButtonCheckedIcon fontSize="small" color="primary" />
              ) : (
                <RadioButtonUncheckedIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearFilterMenu}>
            Reset
          </Button>
          <Button variant="contained" onClick={closeFilterMenu}>
            Ok
          </Button>
        </Box>
      </Menu>
      {showModal && (
        <PatientSelfReflectionModal
          showModal={showModal}
          closeModal={closeModal}
          data={rowData}
          participantId={id}
        />
      )}
    </>
  );
};

export default PatientSelfReflection;
