import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledTableCell,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
  NoDataContainer,
  StyledSortLabel,
} from "../Common/styles/table";
import { errorToastMessage } from "../../utils/toast";
import http from "../../utils/http";
import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { getDateString } from "../../utils/notification";
import { useAppSelector } from "../../Redux/hooks";

type Props = {
  sortOrder: string;
  setSortOrder: (order: string) => void;
  page: number;
  setPage: (page: number) => void;
};

const Exercise = ({ sortOrder, setSortOrder, page, setPage }: Props) => {
  const { refreshExercises } = useAppSelector((state) => state.notification);
  const [data, setData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };
  let [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        let url = `/self-reflection-assignments/status?page=${
          page + 1
        }&size=${pageSize}&status=completed`;
        if (sortOrder !== "") {
          url += `&order=${sortOrder}`;
        }
        const res = await http.get(url);
        const resData = res?.data?.data?.rows?.map((item: any) => {
          return {
            id: item?.id,
            userId: item?.assigned?.code || "",
            title: item?.selfReflectionExercise?.title || "",
            status: item?.status || "",
            date: getDateString(item?.completedAt || item?.updatedAt),
            time: item?.completedAt
              ? DateTime?.fromISO(item?.completedAt)?.toFormat("hh:mm a")
              : item?.updatedAt
              ? DateTime?.fromISO(item?.updatedAt)?.toFormat("hh:mm a")
              : "",
          };
        });
        setData(resData);
        setTotalCount(res?.data?.data?.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        errorToastMessage(error as Error);
      }
    };
    getData();
  }, [page, sortOrder, refreshExercises]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());

    if (sortOrder) {
      params.set("order", sortOrder);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page, sortOrder]);

  const handleSort = (order: string) => {
    if (sortOrder === order) {
      setSortOrder("");
    } else {
      setSortOrder(order);
    }
  };

  const SortLabel = () => {
    return (
      <>
        <StyledSortLabel
          active={sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc")}
        />
        <StyledSortLabel
          active={sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc")}
        />
      </>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>User ID</StyledTableCell>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>
              Time
              <SortLabel />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data?.length > 0 && (
          <>
            <TableBody>
              {data?.map((item: any) => (
                <TableRow key={item?.id}>
                  <StyledTableCell>{item?.userId || "-"}</StyledTableCell>
                  <StyledTableCell>{item?.title || "-"}</StyledTableCell>
                  <StyledTableCell>{item?.status || "-"}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    {item?.date === "Today"
                      ? item?.time
                      : item?.date + " " + item?.time || "-"}

                    {item?.date === "Today" && (
                      <CircleIcon
                        color="secondary"
                        fontSize="small"
                        sx={{
                          position: "relative",
                          top: "-3px",
                        }}
                      />
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalCount > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalCount}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data?.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default Exercise;
