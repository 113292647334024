import { useCallback, useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useDropzone } from "react-dropzone";

import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addBotResponse,
  addResponseMsg,
  changeBotResponsesProperty,
  changeResponseMsgProperty,
  changeResponseMsgType,
  deleteBotResponse,
  deleteResponseMsg,
  setCbBuilderLoader,
} from "../../../../Redux/reducers/chatbotBuilderSlice";
import { botResponsesType } from "../../../../types/chatbotBuilder";
import {
  CbBuilderStepCategories,
  StepOperatorMap,
} from "../../../../utils/chatbotBuilder";
import { ImageUploadIcon, ScheduleIcon, VideoUploadIcon } from "../Icons";
import ArticleIcon from "@mui/icons-material/Article";
import {
  BotAddon,
  EducationAddon,
  FAQEducationAddon,
  // NotificationSetup,
} from "./Addons";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import { NoAnswerOptionStyle, UploadWrapper } from "../chatbotBuilder.style";

type UploadProps = {
  image: any;
  msgIndex: number;
  index: number;
  editable: boolean;
  type: string;
};

const UploadItem: React.FC<UploadProps> = ({
  image,
  msgIndex,
  type,
  index,
  editable,
}) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 15 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 15 MB!");
            return;
          }
          dispatch(setCbBuilderLoader(true));
          const url = await uploadFile(file, type);
          dispatch(
            changeResponseMsgProperty({
              value: url,
              index,
              msgIndex,
              type: "attachmentUrl",
            })
          );
          dispatch(setCbBuilderLoader(false));
        }
      } catch (err) {
        dispatch(setCbBuilderLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, type, index, msgIndex]
  );

  let accept = "";
  if (type === "image") {
    accept = "image/*";
  }
  if (type === "video") {
    accept = "video/*";
  }
  if (type === "document") {
    accept = ".txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf";
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: editable ? onDrop : undefined,
    multiple: false,
    accept: {
      [accept]: [],
    },
    noClick: !editable,
  });

  return (
    <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
      <input {...getInputProps()} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {image ? (
          type === "image" ? (
            <img src={image} className="preview-image" alt="preview" />
          ) : (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File added. Drop Files to change
            </Typography>
          )
        ) : (
          <>
            {type === "image" && <ImageUploadIcon />}
            {type === "video" && <VideoUploadIcon />}
            {type === "document" && <ArticleIcon htmlColor="grey" />}
            <Typography
              variant="subtitle1"
              fontWeight={"medium"}
              ml={2}
              color="#6B7280"
            >
              Drop Files to upload
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const marks = [
  {
    label: (
      <>
        <strong>Fast</strong>
        <br />
        <strong>100ms</strong>
      </>
    ),
    value: 100,
  },
  {
    label: (
      <>
        <strong>Normal</strong>
        <br />
        <strong>1000ms</strong>
      </>
    ),
    value: 1000,
  },
  {
    label: (
      <>
        <strong>Slow</strong>
        <br />
        <strong>3000ms</strong>
      </>
    ),
    value: 3000,
  },
];

type Props = {
  botResponses: botResponsesType[];
  options: any;
  steps: any;
  stepType: string;
  currentStepId: string;
  isFaq: boolean;
  editable: boolean;
};

const BotResponses = ({
  botResponses,
  options,
  steps,
  stepType,
  currentStepId,
  isFaq,
  editable,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<{
    parentIndex: number;
    msgIndex: number;
  }>({
    parentIndex: 0,
    msgIndex: 0,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
    msgIndex: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex({
      parentIndex: index,
      msgIndex,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeValue = (
    value: any,
    index: number,
    type: "isDefault" | "answer" | "nextStep" | "operator"
  ) => {
    dispatch(changeBotResponsesProperty({ value, index, type }));
  };

  const addResponse = () => {
    if (stepType === "yes_no" && botResponses.length >= 2) {
      return;
    }
    dispatch(addBotResponse());
  };

  const deleteResponse = (index: number) => {
    dispatch(deleteBotResponse({ index }));
  };

  const changeResponseMsgValue = (
    value: any,
    index: number,
    msgIndex: number,
    type: "text" | "delay" | "attachmentUrl"
  ) => {
    dispatch(changeResponseMsgProperty({ value, index, msgIndex, type }));
  };

  const changeResponseType = (
    type: string,
    index: number,
    msgIndex: number
  ) => {
    dispatch(changeResponseMsgType({ type, index, msgIndex }));
  };

  const deleteMsg = (index: number, msgIndex: number) => {
    dispatch(deleteResponseMsg({ index, msgIndex }));
  };

  const addMsg = (index: number) => {
    dispatch(addResponseMsg({ index }));
  };

  return (
    <>
      {stepType !== "gpt" ? (
        <>
          {botResponses.map((response: botResponsesType, index: number) => {
            return (
              <Box mb={3} key={response?.id}>
                <Box mb={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={response?.isDefault}
                        onChange={(e) =>
                          changeValue(e.target.checked, index, "isDefault")
                        }
                      />
                    }
                    label="Default Response for the bot"
                  />
                </Box>
                {!response?.isDefault && (
                  <>
                    <Typography
                      variant="subtitle1"
                      mb={1.25}
                      fontWeight="medium"
                    >
                      Bot condition
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        mb: 3,
                        alignItems: "flex-end",
                        gap: 3,
                      }}
                    >
                      {CbBuilderStepCategories?.choice.includes(stepType) ? (
                        <Box sx={{ maxWidth: "70%", flex: 1 }}>
                          <Typography
                            variant="subtitle1"
                            mb={1.25}
                            fontWeight="medium"
                          >
                            Answer Option
                          </Typography>
                          <Select
                            value={response?.answer || ""}
                            fullWidth
                            onChange={(e) =>
                              changeValue(e.target.value, index, "answer")
                            }
                          >
                            {options?.map(
                              (option: { id: string; text: string }) => {
                                return option?.text ? (
                                  <MenuItem key={option?.id} value={option?.id}>
                                    {option?.text}
                                  </MenuItem>
                                ) : null;
                              }
                            )}
                          </Select>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            flex: 1,
                            minWidth: "1px",
                            maxWidth: stepType === "calendar" ? "360px" : "70%",
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 3,
                          }}
                        >
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="subtitle1"
                              mb={1.25}
                              fontWeight="medium"
                            >
                              Answer Option
                            </Typography>
                            {stepType === "calendar" ? (
                              <DatePicker
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ maxWidth: "200px" }}
                                    inputProps={{
                                      ...params.inputProps,
                                      readOnly: true,
                                      placeholder: "DD/MM/YYYY",
                                    }}
                                  />
                                )}
                                value={
                                  response?.answer
                                    ? DateTime.fromFormat(
                                        response?.answer,
                                        "yyyy-LL-dd"
                                      )
                                    : null
                                }
                                onChange={(newValue: any) => {
                                  changeValue(
                                    newValue?.toFormat("yyyy'-'LL'-'dd"),
                                    index,
                                    "answer"
                                  );
                                }}
                              />
                            ) : (
                              <TextField
                                value={response?.answer || ""}
                                fullWidth
                                onChange={(e) =>
                                  changeValue(e.target.value, index, "answer")
                                }
                                type={
                                  CbBuilderStepCategories["number"]?.includes(
                                    stepType
                                  )
                                    ? "number"
                                    : "text"
                                }
                                placeholder="Type your text here..."
                              />
                            )}
                          </Box>
                          <Box sx={{ maxWidth: "160px", flex: 1 }}>
                            <Typography
                              variant="subtitle1"
                              mb={1.25}
                              fontWeight="medium"
                            >
                              Operator
                            </Typography>
                            <Select
                              value={response?.operator || ""}
                              fullWidth
                              onChange={(e) =>
                                changeValue(e.target.value, index, "operator")
                              }
                            >
                              {StepOperatorMap[stepType]?.map((op: any) => {
                                return (
                                  <MenuItem key={op.value} value={op.value}>
                                    {op.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Box>
                        </Box>
                      )}
                      <Box>
                        <IconButton
                          disabled={botResponses.length < 2 || !editable}
                          onClick={() => deleteResponse(index)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                )}
                <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                  Bot response message
                </Typography>
                {response?.responseMsgs.map(
                  (message: any, msgIndex: number) => {
                    return (
                      <Box key={message?.id}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            mb: 2,
                            alignItems: "flex-end",
                          }}
                        >
                          <Box sx={{ flex: 2, minWidth: "1px" }}>
                            <Typography
                              variant="subtitle1"
                              mb={1.25}
                              fontWeight="medium"
                            >
                              Chat bubble
                            </Typography>
                            <TextField
                              fullWidth
                              value={message.text || ""}
                              placeholder="Type your text here..."
                              onChange={(e) =>
                                changeResponseMsgValue(
                                  e.target.value,
                                  index,
                                  msgIndex,
                                  "text"
                                )
                              }
                            />
                          </Box>
                          <Box sx={{ flex: 1, minWidth: "1px" }}>
                            <Typography
                              variant="subtitle1"
                              mb={1.25}
                              fontWeight="medium"
                            >
                              Chat type
                            </Typography>
                            <Select
                              value={message?.type}
                              fullWidth
                              onChange={(e) =>
                                changeResponseType(
                                  e.target.value,
                                  index,
                                  msgIndex
                                )
                              }
                            >
                              <MenuItem key="text" value="text">
                                Text
                              </MenuItem>
                              <MenuItem key="video" value="video">
                                Video
                              </MenuItem>
                              <MenuItem key="document" value="document">
                                document
                              </MenuItem>
                              {/* <MenuItem key="reference" value="reference">
                                Reference
                              </MenuItem> */}
                              <MenuItem key="image" value="image">
                                Image
                              </MenuItem>
                              {/* <MenuItem key="link" value="link">
                                Link
                              </MenuItem>
                              <MenuItem key="lesson" value="educationLesson">
                                Education Module
                              </MenuItem>
                              <MenuItem key="bot" value="bot">
                                Chat Bot
                              </MenuItem>
                              <MenuItem key="feet_diary" value="feet_diary">
                                Feet Diary
                              </MenuItem>
                              <MenuItem key="food_diary" value="food_diary">
                                Food Diary
                              </MenuItem>
                              <MenuItem key="medication" value="medication">
                                Medication Diary
                              </MenuItem> */}
                            </Select>
                          </Box>
                          <Box
                            sx={{
                              minWidth: "1px",
                            }}
                          >
                            <IconButton
                              onClick={() => deleteMsg(index, msgIndex)}
                              sx={{ mr: 1 }}
                              disabled={!editable}
                              color="error"
                            >
                              <Delete />
                            </IconButton>
                            <IconButton
                              onClick={(e) => handleClick(e, index, msgIndex)}
                            >
                              <ScheduleIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        {message?.type === "link" && (
                          <Box mb={3}>
                            <Typography
                              variant="subtitle1"
                              mb={1.25}
                              fontWeight="medium"
                            >
                              Redirection URL
                            </Typography>
                            <TextField
                              fullWidth
                              value={message?.attachmentUrl}
                              placeholder="Type your url here..."
                              onChange={(e) =>
                                changeResponseMsgValue(
                                  e.target.value,
                                  index,
                                  msgIndex,
                                  "attachmentUrl"
                                )
                              }
                            />
                          </Box>
                        )}
                        {(message?.type === "image" ||
                          message?.type === "video" ||
                          message?.type === "document") && (
                          <Box mb={3}>
                            <UploadItem
                              image={message?.attachmentUrl}
                              index={index}
                              type={message?.type}
                              msgIndex={msgIndex}
                              editable={editable}
                            />
                          </Box>
                        )}
                        {message?.type === "educationLesson" && (
                          <>
                            {isFaq ? (
                              <>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  mb={2}
                                >
                                  Patient Education Module
                                </Typography>
                                <EducationAddon
                                  message={message}
                                  index={index}
                                  msgIndex={msgIndex}
                                  editable={editable}
                                />
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  mb={2}
                                >
                                  Caregiver Education Module
                                </Typography>
                                <FAQEducationAddon
                                  message={message}
                                  index={index}
                                  msgIndex={msgIndex}
                                  editable={editable}
                                />
                              </>
                            ) : (
                              <EducationAddon
                                message={message}
                                index={index}
                                msgIndex={msgIndex}
                                editable={editable}
                              />
                            )}
                          </>
                        )}
                        {message?.type === "bot" && (
                          <BotAddon
                            message={message}
                            index={index}
                            msgIndex={msgIndex}
                            isFaq={isFaq}
                            editable={editable}
                          />
                        )}
                        {/* {!isFaq &&
                          message?.type !== "link" &&
                          message?.type !== "image" &&
                          message?.type !== "text" &&
                          message?.type !== "reference" && (
                            <NotificationSetup
                              message={message}
                              index={index}
                              msgIndex={msgIndex}
                              isFaq={isFaq}
                              editable={editable}
                            />
                          )} */}
                      </Box>
                    );
                  }
                )}
                {editable && (
                  <Button
                    variant="outlined"
                    sx={{ mt: 1, mb: 3 }}
                    startIcon={<Add />}
                    onClick={() => addMsg(index)}
                  >
                    Add new chat bubble
                  </Button>
                )}
                <Box sx={{ maxWidth: "70%", flex: 1 }}>
                  <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                    Next Step
                  </Typography>
                  <Select
                    value={response?.nextStep || ""}
                    fullWidth
                    onChange={(e) =>
                      changeValue(e.target.value, index, "nextStep")
                    }
                  >
                    <MenuItem key={"default"} value={"default"}>
                      Default
                    </MenuItem>
                    {steps.map((step: any) => (
                      <MenuItem
                        key={step?.id}
                        value={step?.id}
                        disabled={currentStepId === step?.id}
                      >
                        {step?.name || step?.label}
                      </MenuItem>
                    ))}
                    <MenuItem key="exit" value={"exit"}>
                      Exit Bot
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            );
          })}
          {(stepType !== "yes_no" ||
            (stepType === "yes_no" && botResponses?.length < 2)) &&
            editable && (
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={addResponse}
              >
                Add new response
              </Button>
            )}
        </>
      ) : (
        <>
          <Box sx={NoAnswerOptionStyle}>
            <Typography variant="subtitle1">
              There are no Bot Response for this step type
            </Typography>
          </Box>
        </>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: 400,
            p: 2,
            mt: 1,
          },
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium" mb={3}>
          Customize Delay Message
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter delay in milliseconds"
          type="number"
          value={
            botResponses[selectedIndex?.parentIndex]?.responseMsgs[
              selectedIndex?.msgIndex
            ]?.delay | 0
          }
          onChange={(e) =>
            changeResponseMsgValue(
              parseInt(e.target.value || "0"),
              selectedIndex?.parentIndex,
              selectedIndex?.msgIndex,
              "delay"
            )
          }
        />
        <Box sx={{ p: 4 }}>
          <Slider
            min={100}
            max={3000}
            step={100}
            valueLabelDisplay="auto"
            marks={marks}
            value={
              botResponses[selectedIndex?.parentIndex]?.responseMsgs[
                selectedIndex?.msgIndex
              ]?.delay | 0
            }
            onChange={(_, value) =>
              changeResponseMsgValue(
                value,
                selectedIndex?.parentIndex,
                selectedIndex?.msgIndex,
                "delay"
              )
            }
          />
        </Box>
      </Popover>
    </>
  );
};

export default BotResponses;
