import {
  Modal,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";

import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import * as yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { useMemo, useState } from "react";
import { debounce } from "lodash";
import { LabelStyle } from "../Common/styles/form";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { DateTime } from "luxon";

const RemindMe = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const schema = yup.object().shape({
  selfReflectionExercise: yup.object().required("*Required"),
  assignTo: yup
    .array()
    .required("*Required")
    .min(1, "At least one assignee is required"),
  dueDate: yup.string().required("*Required"),
  triggerReminder: yup.number().required("*Required"),
  schedule: yup.string().required("*Required"),

  scheduleTime: yup.string().when("schedule", {
    is: (val: string) => val === "schedule",
    then: (schema) => schema.required("*Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const SelfReflectionModal = ({
  showModal,
  closeModal,
  refreshPage,
  data,
}: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState("");
  const [options, setOptions] = useState<any>([]);
  const [userOptions, setUserOptions] = useState<any>([]);
  const coachId = localStorage.getItem("coachId");

  const handleExerciseSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader("title");
            let url = `/self-reflection?pagination=false&search=${value}`;
            const res = await http.get(url);
            const resData = res.data?.data?.map((item: any) => {
              return {
                name: item.title,
                id: item.id,
              };
            });

            setOptions(resData);
            setSearchLoader("");
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader("");
        }
      }, 500),
    []
  );

  const handleUserSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader("user");
            let url = `/coach/participant?search=${value}&doctorId=${coachId}`;
            const res: AxiosResponse = await http.get(url);
            const patientsData = res.data.data?.map((item: any) => {
              return {
                id: item?.participant?.id,
                name: item?.participant?.code,
              };
            });
            setUserOptions(patientsData);
            setSearchLoader("");
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader("");
        }
      }, 500),
    [coachId]
  );

  const handleSubmit = async (values: any) => {
    try {
      setSubmitLoader(true);
      const newValues: any = {
        scheduledDate:
          values.schedule === "schedule"
            ? DateTime.fromISO(values.scheduleTime)
                .startOf("day")
                .toUTC()
                .toISO()
            : DateTime.local().toUTC().toISO(),
        dueDate: values.dueDate.endOf("day").toUTC().toISO(),
        reminderWindowLength: values.triggerReminder,
      };
      if (!data) {
        newValues.addIds = values.assignTo.map((item: any) => item.id);
      }
      let res: AxiosResponse;
      if (!data) {
        res = await http.post(
          `/self-reflection/${values.selfReflectionExercise.id}/assign`,
          newValues
        );
      } else {
        res = await http.patch(
          `/self-reflection-assignments/${data?.id}`,
          newValues
        );
      }

      toastMessage("success", res.data.message);
      setSubmitLoader(false);
      closeModal();
      refreshPage();
    } catch (error) {
      errorToastMessage(error as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title="Assign self-reflection exercise"
          onCloseClick={closeModal}
        />

        <Formik
          initialValues={{
            selfReflectionExercise: data?.selfReflectionId
              ? {
                  id: data?.selfReflectionId,
                  name: data?.userTitle,
                }
              : null,
            assignTo: data?.assignedToId
              ? [{ name: data?.assignedTo, id: data?.assignedToId }]
              : [],
            dueDate: data?.dueDate
              ? DateTime.fromFormat(data?.dueDate || "", "dd LLL yyyy")
              : null,
            triggerReminder: data?.reminderWindowLength
              ? data?.reminderWindowLength
              : 1,
            schedule: "schedule",
            scheduleTime: data?.scheduledDate ? data?.scheduledDate : null,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            errors,
            touched,
            getFieldProps,

            setFieldValue,
            values,
          }) => (
            <Form>
              <FormControl
                sx={{ width: "100%" }}
                disabled={data?.id ? true : false}
              >
                <FormLabel htmlFor="selfReflectionExercise" sx={LabelStyle}>
                  Select self reflection exercises
                </FormLabel>
                <Autocomplete
                  disabled={data?.id ? true : false}
                  id="selfReflectionExercise"
                  options={options}
                  value={values.selfReflectionExercise || null}
                  filterOptions={(x) => x}
                  getOptionLabel={(option: any) => option?.name}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") handleExerciseSearch(value);
                  }}
                  onChange={(_1: any, newValue: any) => {
                    setFieldValue("selfReflectionExercise", newValue);
                  }}
                  loading={searchLoader === "title"}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  renderInput={(params) => (
                    <TextField
                      placeholder="Search..."
                      error={
                        touched?.selfReflectionExercise &&
                        errors?.selfReflectionExercise
                          ? true
                          : false
                      }
                      helperText={
                        touched?.selfReflectionExercise &&
                        errors?.selfReflectionExercise
                          ? (errors.selfReflectionExercise as string)
                          : " "
                      }
                      {...params}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                sx={{ width: "100%" }}
                disabled={data?.id ? true : false}
              >
                <FormLabel sx={LabelStyle} htmlFor="assignTo">
                  Assign to
                </FormLabel>
                <Autocomplete
                  disabled={data?.id ? true : false}
                  multiple
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") handleUserSearch(value);
                  }}
                  onChange={(_1: any, value: any) => {
                    setFieldValue("assignTo", value);
                  }}
                  options={userOptions}
                  value={values.assignTo || null}
                  getOptionLabel={(option: any) => option?.name}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option?.id === value?.id;
                  }}
                  loading={searchLoader === "user"}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        touched?.assignTo && errors?.assignTo ? true : false
                      }
                      placeholder="Search..."
                    />
                  )}
                />
                <FormHelperText error>
                  {touched?.assignTo && errors?.assignTo
                    ? (errors.assignTo as string)
                    : " "}
                </FormHelperText>
              </FormControl>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5%",
                }}
              >
                <FormControl
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormLabel htmlFor="dueDate" sx={LabelStyle}>
                    Due date
                  </FormLabel>
                  <DatePicker
                    inputFormat="dd/LL/yyyy"
                    disablePast
                    disableHighlightToday
                    shouldDisableDate={(date) => {
                      return date < values.scheduleTime;
                    }}
                    value={values.dueDate}
                    onChange={(date) => {
                      setFieldValue("dueDate", date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        id="dueDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "DD/MM/YYYY",
                        }}
                        error={
                          touched?.dueDate && errors?.dueDate ? true : false
                        }
                      />
                    )}
                  />
                  <FormHelperText error>
                    {touched?.dueDate && errors?.dueDate
                      ? (errors?.dueDate as string)
                      : " "}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <FormLabel htmlFor="triggerReminder" sx={LabelStyle}>
                    Trigger Reminder In
                  </FormLabel>

                  <Select
                    value={values.triggerReminder}
                    onChange={(e: any) => {
                      setFieldValue(
                        "triggerReminder",
                        parseInt(e.target.value)
                      );
                    }}
                    error={
                      touched?.triggerReminder && errors?.triggerReminder
                        ? true
                        : false
                    }
                  >
                    {RemindMe.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error>
                    {touched?.triggerReminder && errors?.triggerReminder
                      ? (errors?.triggerReminder as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>

              <FormControl style={{ width: "100%" }}>
                <FormLabel sx={LabelStyle}>Schedule</FormLabel>
                <Field name="schedule">
                  {({ field }: FieldProps) => (
                    <RadioGroup
                      {...field}
                      sx={{ display: "flex", flexDirection: "row" }}
                      onChange={(e) => {
                        setFieldValue("schedule", e.target.value);
                        if (e.target.value === "assignNow")
                          setFieldValue("scheduleTime", null);
                      }}
                    >
                      <FormControlLabel
                        value="assignNow"
                        control={<Radio />}
                        label="Assign Now"
                      />
                      <FormControlLabel
                        value="schedule"
                        control={<Radio />}
                        label="Schedule"
                      />
                      <FormHelperText error>
                        {touched?.schedule && errors?.schedule
                          ? (errors?.schedule as string)
                          : " "}
                      </FormHelperText>
                    </RadioGroup>
                  )}
                </Field>
              </FormControl>
              {(getFieldProps("schedule").value as string) === "schedule" && (
                <FormControl>
                  <DatePicker
                    value={values.scheduleTime}
                    disablePast
                    inputFormat="dd/LL/yyyy"
                    onChange={(date) => {
                      setFieldValue("scheduleTime", date);

                      if (values?.dueDate && values?.dueDate < date) {
                        setFieldValue("dueDate", null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          placeholder: "DD/MM/YYYY",
                        }}
                        error={
                          touched?.scheduleTime && errors?.scheduleTime
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                  <FormHelperText error>
                    {touched?.scheduleTime && errors?.scheduleTime
                      ? (errors?.scheduleTime as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              )}

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  marginTop: "40px",
                }}
              >
                {!submitLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Assign
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={18} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default SelfReflectionModal;
