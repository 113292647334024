import { useCallback, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

import { useAppDispatch } from "../../../../Redux/hooks";
import {
  addBotMessage,
  changeBotMessageProperty,
  deleteBotMessage,
  setCbBuilderLoader,
} from "../../../../Redux/reducers/chatbotBuilderSlice";
import { botMessagesType } from "../../../../types/chatbotBuilder";
import { ImageUploadIcon, ScheduleIcon, VideoUploadIcon } from "../Icons";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/upload";
import { UploadWrapper } from "../chatbotBuilder.style";

type UploadProps = {
  attachUrl: any;
  type: string;
  accept: string;
  index: number;
  editable: boolean;
};

const UploadItem: React.FC<UploadProps> = ({
  attachUrl,
  type,
  accept,
  index,
  editable,
}) => {
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 15 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 15 MB!");
            return;
          }
          dispatch(setCbBuilderLoader(true));
          const url = await uploadFile(file, type);
          dispatch(
            changeBotMessageProperty({
              value: url,
              changeIndex: index,
              type: "attachmentUrl",
            })
          );
          dispatch(setCbBuilderLoader(false));
        }
      } catch (err) {
        dispatch(setCbBuilderLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, type, index]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: editable ? onDrop : undefined,
    multiple: false,
    accept: {
      [accept]: [],
    },
    noClick: !editable,
  });

  return (
    <Box {...getRootProps({ className: "dropzone" })} sx={UploadWrapper}>
      <input {...getInputProps()} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {attachUrl ? (
          type === "image" ? (
            <img src={attachUrl} className="preview-image" alt="preview" />
          ) : (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File added. Drop Files to change
            </Typography>
          )
        ) : (
          <>
            {type === "image" ? <ImageUploadIcon /> : <VideoUploadIcon />}
            <Typography
              variant="subtitle1"
              fontWeight={"medium"}
              ml={2}
              color="#6B7280"
            >
              Drop Files to upload
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const marks = [
  {
    label: (
      <>
        <strong>Fast</strong>
        <br />
        <strong>100ms</strong>
      </>
    ),
    value: 100,
  },
  {
    label: (
      <>
        <strong>Normal</strong>
        <br />
        <strong>1000ms</strong>
      </>
    ),
    value: 1000,
  },
  {
    label: (
      <>
        <strong>Slow</strong>
        <br />
        <strong>3000ms</strong>
      </>
    ),
    value: 3000,
  },
];

type Props = {
  botMessages: botMessagesType[];
  editable: boolean;
};

const BotMessage = ({ botMessages, editable }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeValue = (value: any, type: any, index: number) => {
    dispatch(changeBotMessageProperty({ value, changeIndex: index, type }));
  };

  const addMessage = () => {
    dispatch(addBotMessage());
  };

  const deleteMessage = (index: number) => {
    dispatch(deleteBotMessage({ index }));
  };

  return (
    <>
      {botMessages?.map((message: botMessagesType, index: number) => {
        return (
          <Box key={message?.id} mb={3}>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ flex: 2, minWidth: "1px" }}>
                <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                  {message?.type === "text"
                    ? "Chat bubble"
                    : message?.type === "image"
                    ? "Image title"
                    : message?.type === "video"
                    ? "Video title"
                    : "Chat bubble"}
                </Typography>
                {message?.type === "text" || message?.type === "reference" ? (
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    value={message.text || ""}
                    placeholder="Type your text here..."
                    onChange={(e) => changeValue(e.target.value, "text", index)}
                  />
                ) : (
                  <TextField
                    fullWidth
                    value={message.text || ""}
                    placeholder="Type your text here..."
                    onChange={(e) => changeValue(e.target.value, "text", index)}
                  />
                )}
              </Box>
              <Box sx={{ flex: 1, minWidth: "1px" }}>
                <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                  Chat type
                </Typography>
                <Select
                  value={message?.type}
                  fullWidth
                  onChange={(e) => changeValue(e.target.value, "type", index)}
                  sx={{ textTransform: "capitalize" }}
                >
                  {["text", "image", "video", "document"].map((type) => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  minWidth: "1px",
                }}
              >
                <IconButton
                  sx={{ mr: 1 }}
                  disabled={botMessages.length < 2 || !editable}
                  onClick={() => deleteMessage(index)}
                  color="error"
                >
                  <Delete />
                </IconButton>
                <IconButton onClick={(e) => handleClick(e, index)}>
                  <ScheduleIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ width: "85%", mt: 1 }}>
              {message?.type !== "text" &&
                message?.type !== "link" &&
                message?.type !== "reference" && (
                  <UploadItem
                    attachUrl={message?.attachmentUrl}
                    type={message?.type}
                    accept={
                      message?.type === "image"
                        ? "image/*"
                        : message?.type === "video"
                        ? "video/*"
                        : ".txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf"
                    }
                    index={index}
                    editable={editable}
                  />
                )}
              {(message?.type === "video" || message?.type === "image") && (
                <>
                  <Typography
                    variant="body1"
                    fontWeight="medium"
                    sx={{ textAlign: "center" }}
                  >
                    Or
                  </Typography>
                  <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                    {message?.type === "video" ? "Video Link" : "Image Link"}
                  </Typography>
                  <TextField
                    fullWidth
                    value={message?.attachmentUrl || ""}
                    placeholder="Type your link here..."
                    onChange={(e) =>
                      changeValue(e.target.value, "attachmentUrl", index)
                    }
                  />
                </>
              )}
              {message?.type === "link" && (
                <Box>
                  <Typography variant="subtitle1" mb={1.25} fontWeight="medium">
                    Redirection URL
                  </Typography>
                  <TextField
                    fullWidth
                    value={message?.attachmentUrl || ""}
                    placeholder="Type your link here..."
                    onChange={(e) =>
                      changeValue(e.target.value, "attachmentUrl", index)
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
      {editable && (
        <Button variant="outlined" startIcon={<Add />} onClick={addMessage}>
          Add new chat bubble
        </Button>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: 400,
            p: 2,
            mt: 1,
          },
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium" mb={3}>
          Customize Delay Message
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter delay in milliseconds"
          type="number"
          value={botMessages[selectedIndex].delay}
          onChange={(e) =>
            changeValue(parseInt(e.target.value || "0"), "delay", selectedIndex)
          }
        />
        <Box sx={{ p: 4 }}>
          <Slider
            min={100}
            max={3000}
            step={100}
            valueLabelDisplay="auto"
            marks={marks}
            value={botMessages[selectedIndex].delay}
            onChange={(_, value) => changeValue(value, "delay", selectedIndex)}
          />
        </Box>
      </Popover>
    </>
  );
};

export default BotMessage;
