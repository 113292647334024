import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setPreviewMode } from "../../../Redux/reducers/reflectionBuilderSlice";
import { highlightScript, previewLesson } from "../../../utils/education";
import CMSPreview from "./CMSPreview";
import CMSThemeWrapper from "./CMSThemeWrapper";

type Props = {
  pageId: any;
};

const BuilderPreview: React.FC<Props> = ({ pageId }) => {
  const [isDarkMode, setDarkMode] = useState(false);

  const dispatch = useAppDispatch();
  const { lesson, lang, title } = useAppSelector(
    (state) => state.reflectionBuilder
  );

  const [pages, setPages] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sections, setSections] = useState<any[]>([]);

  useEffect(() => {
    let newPages = [...lesson.pages];
    newPages.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    newPages = newPages.map((item: any) => {
      if (item.sections && item.sections.length > 0) {
        const newSections = [...item.sections];
        newSections.sort(function (a: any, b: any) {
          return a.position - b.position;
        });
        return {
          ...item,
          sections: newSections,
        };
      } else {
        return item;
      }
    });
    setPages(newPages);
  }, [lesson, setPages]);

  useEffect(() => {
    if (pageId) {
      const index = pages.findIndex((page: any) => {
        return page.id === pageId;
      });
      if (index !== -1) {
        setCurrentPage(index + 1);
      } else {
        setCurrentPage(1);
      }
    } else {
      setCurrentPage(pages.length);
    }
  }, [pageId, setCurrentPage, pages]);

  useEffect(() => {
    const sections = pages[currentPage - 1]?.sections;
    if (sections) {
      const { previewSections } = previewLesson(sections);
      setSections(previewSections);
    }
  }, [setSections, currentPage, pages]);

  const handlePagination = (offset: number) => {
    const newPage = currentPage + offset;
    if (newPage > 0 && newPage <= pages.length) {
      setCurrentPage(newPage);
    } else if (newPage === pages.length + 1 || newPage === pages.length + 2) {
      finishHandler();
    }
  };

  const moveToPage = (newPage: number) => {
    if (newPage > 0 && newPage <= pages.length) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = highlightScript();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const finishHandler = () => {
    dispatch(setPreviewMode(false));
  };

  const setMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDarkMode(e.target.checked);
  };

  return (
    <CMSThemeWrapper isDarkMode={isDarkMode}>
      <CMSPreview
        isDarkMode={isDarkMode}
        setMode={setMode}
        title={title}
        position={lesson.position}
        sections={sections}
        handleBack={finishHandler}
        currentPage={currentPage}
        handlePagination={handlePagination}
        total={pages.length}
        moveToPage={moveToPage}
        finishHandler={finishHandler}
        lang={lang}
      />
    </CMSThemeWrapper>
  );
};

export default BuilderPreview;
