import { GridRowSelectionModel } from "@mui/x-data-grid";
import { pageSize } from "./../../Components/Common/styles/table";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PaginationProps = {
  page: number;
  pageSize: number;
};

export interface ParticipantsState {
  urlLoaded: boolean;
  loading: boolean;
  type: string;
  searchText: string;
  doctorsList: any[];
  participantsData: any[];
  totalParticipants: number;
  filterId: string;
  paginationModel: PaginationProps;
  toggleLoader: boolean;
  selectedRows: GridRowSelectionModel;

  activePtpType: string;
}

const initialState: ParticipantsState = {
  urlLoaded: false,
  loading: true,
  toggleLoader: false,
  type: "active",
  searchText: "",
  filterId: "",
  paginationModel: {
    page: 0,
    pageSize: pageSize,
  },
  selectedRows: [],

  doctorsList: [],
  participantsData: [],
  totalParticipants: 0,

  activePtpType: "assigned",
};

export const participantsSlice = createSlice({
  name: "participants",
  initialState: initialState,
  reducers: {
    setParticipantsLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDoctorsList: (state, action: PayloadAction<any>) => {
      state.doctorsList = action.payload;
    },

    setParticipantsDetails: (state, action: PayloadAction<any>) => {
      state.participantsData = action.payload;
    },
    setParticipantsCount: (state, action: PayloadAction<number>) => {
      state.totalParticipants = action.payload;
    },
    setParticipantsPage: (state, action: PayloadAction<PaginationProps>) => {
      state.paginationModel = action.payload;
    },
    setParticipantsType: (state, action: PayloadAction<string>) => {
      state.filterId = "";
      state.paginationModel = {
        page: 0,
        pageSize: pageSize,
      };
      state.searchText = "";
      state.selectedRows = [];
      state.type = action.payload;
    },
    setActiveParticipantsType: (state, action: PayloadAction<string>) => {
      state.filterId = "";
      state.paginationModel = {
        page: 0,
        pageSize: pageSize,
      };
      state.searchText = "";
      state.selectedRows = [];
      state.activePtpType = action.payload;
    },
    setParticipantsFilterId: (state, action: PayloadAction<string>) => {
      state.paginationModel.page = 0;
      state.filterId = action.payload;
    },
    setParticipantsSearchText: (state, action: PayloadAction<string>) => {
      state.paginationModel.page = 0;
      state.searchText = action.payload;
    },
    setParticipantDefaults: (
      state,
      action: PayloadAction<{
        search: string;
        pagination: PaginationProps;
        filterId: string;
        type: string;
        activeTabType: string;
      }>
    ) => {
      state.type = action.payload.type;
      state.searchText = action.payload.search;
      state.filterId = action.payload.filterId;
      state.paginationModel = action.payload.pagination;
      state.activePtpType = action.payload.activeTabType;
      state.urlLoaded = true;
    },
    setUrlLoadedFalse: (state) => {
      state.urlLoaded = false;
    },
    setParticipantToggle: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setSelectedRows: (state, action: PayloadAction<string[]>) => {
      state.selectedRows = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setParticipantsLoader,
  setParticipantsDetails,
  setParticipantsCount,
  setParticipantsPage,
  setParticipantsType,
  setParticipantsFilterId,
  setDoctorsList,
  setParticipantsSearchText,
  setUrlLoadedFalse,
  setParticipantDefaults,
  setParticipantToggle,
  setSelectedRows,
  setActiveParticipantsType,
  reset,
} = participantsSlice.actions;

export default participantsSlice.reducer;
