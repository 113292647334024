import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  IconButton,
  Box,
  Typography,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NoDataContainer,
  StyledSortLabel,
  StyledTableCell,
} from "../../../../Common/styles/table";
import { errorToastMessage } from "../../../../../utils/toast";
import http from "../../../../../utils/http";
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  AccMap,
  getQuizData,
  getSearchData,
  QuizMap,
  SearchMap,
} from "../../../../../utils/appData";
import { QuizAccordian, SearchAccordian } from "./Accordians";

const History = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("user");
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [data, setData] = useState<any>(null);
  const [sorters, setSorters] = useState<any>(null);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((item) => item !== panel)
      );
    };

  useEffect(() => {
    try {
      setLoading(true);

      const getHistory = async () => {
        const res = await http.get(`activity/learning-history?code=${id}`);
        const resData = res.data.data;

        const formattedData = {
          onboarding: {
            headers: [
              {
                label: "Question",
                key: "question",
              },
              {
                label: "Answer",
                key: "answer",
              },
              {
                label: "Date",
                key: "date",
              },
            ],
            rows: Object.entries(resData?.ONBOARDING_DATA[0]?.data)
              ?.map(([key, value]: any) => ({
                // question: key?.replace(/copy$/i, ""),
                question: key,
                answer: value?.stepChoiceId
                  ? value?.stepChoiceValue?.replace(/<\/?[^>]+(>|$)/g, "")
                  : value?.value,
                date: value?.answeredAt
                  ? DateTime?.fromISO(value?.answeredAt)?.toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : resData?.ONBOARDING_DATA[0]?.updated_at
                  ? DateTime?.fromISO(
                      resData?.ONBOARDING_DATA[0]?.updated_at
                    )?.toFormat("dd/LL/yyyy hh:mm a")
                  : "",
                dateTime:
                  value?.answeredAt || resData?.ONBOARDING_DATA[0]?.updated_at,
              }))
              ?.sort((a: any, b: any) => {
                const prevDate: any = new Date(a?.dateTime);
                const nextDate: any = new Date(b?.dateTime);
                return nextDate - prevDate;
              }),
            // rows : resData?.ONBOARDING_DATA?.map((item: any) => {
            //   return {
            //    question : "",
            //    answer : "",
            //    date : DateTime?.fromISO(item?.updated_at)?.toFormat(
            //     "dd/LL/yyyy hh:mm a" || ""
            //   ),
            //   };
            // })
          },
          // chat: [
          //   {
          //     nutrition: [
          //       // {
          //       //   id: "cnid1",
          //       //   title: "what is healthy eating for people",
          //       //   timeStamp: "{timestamp}",
          //       // },
          //     ],
          //     treatment: [{}],
          //     complications: [{}],
          //   },
          // ],
          // dailyAchievements: resData.DAILY_TASKS_DATA.map((item: any) => {
          //   return {
          //     dialyAchievements: item.title,
          //     timeStamp: DateTime.fromISO(item[`?column?`]).toFormat(
          //       "dd/LL/yyyy hh:mm a"
          //     ),
          //   };
          // }),
          // learningNotes: resData.LEARNING_NOTES.map((item: any) => {
          //   return {
          //     textValue: item.title,
          //     numberValue: item.number_value,
          //     timeStamp: DateTime.fromISO(item[`?column?`]).toFormat(
          //       "dd/LL/yyyy hh:mm a"
          //     ),
          //   };
          // }),
          aoc: {
            headers: [
              {
                label: "Question",
                key: "name",
              },
              {
                label: "Answer",
                key: "value",
              },
              {
                label: "Date",
                key: "date",
              },
            ],
            rows: resData.AOC_DATA.map((item: any) => {
              return {
                name: item?.name,
                value: item?.text,
                date: DateTime?.fromISO(item?.created_at)?.isValid
                  ? DateTime?.fromISO(item?.created_at)?.toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : "",
                dateTime: item?.created_at,
              };
            }),
          },
          faq: {
            headers: [
              {
                label: "Question",
                key: "name",
              },
              {
                label: "Answer",
                key: "value",
              },
              {
                label: "Date",
                key: "date",
              },
            ],
            rows: resData.FAQ_DATA.map((item: any) => {
              return {
                name: item?.bot_step,
                value: item?.value,
                date: DateTime?.fromISO(item?.created_at)?.isValid
                  ? DateTime?.fromISO(item?.created_at)?.toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : "",
                dateTime: item?.created_at,
              };
            }),
          },
          poll: {
            headers: [
              {
                label: "Poll",
                key: "poll",
              },
              {
                label: "Choice ID's",
                key: "choiceId",
              },
              {
                label: "Date",
                key: "date",
              },
            ],
            rows: resData.POLL_DATA.map((item: any) => {
              return {
                poll: item?.title,
                choiceId: item?.label,
                date: DateTime?.fromISO(item?.created_at)?.isValid
                  ? DateTime?.fromISO(item?.created_at)?.toFormat(
                      "dd/LL/yyyy hh:mm a"
                    )
                  : "",
                dateTime: item?.created_at,
              };
            }),
          },
          quiz: getQuizData(resData.QUIZ_DATA),
          search: getSearchData(resData.SEARCHES),

          // search: {
          //   headers: [
          //     {
          //       label: "Search term",
          //       key: "keyword",
          //     },
          //     {
          //       label: "Date",
          //       key: "date",
          //     },
          //   ],
          //   rows: resData?.SEARCHES?.map((item: any) => {
          //     return {
          //       keyword: item?.keyword,
          //       date: DateTime.fromISO(item?.created_at).toFormat(
          //         "dd/LL/yyyy hh:mm a"
          //       ),
          //     };
          //   }),
          // },
          journal: {
            headers: [
              {
                label: "Title",
                key: "title",
              },
              {
                label: "Number value",
                key: "numberValue",
              },
              {
                label: "Date",
                key: "date",
              },
            ],
            rows: resData?.REFLECTION_JOURNAL?.map((item: any) => {
              return {
                title: item?.title,
                numberValue: item?.number_value?.toString(),
                date: DateTime?.fromISO(item?.updated_at).toFormat(
                  "dd/LL/yyyy hh:mm a"
                ),
                dateTime: item?.updated_at,
              };
            }),
          },
        };
        setSorters({
          onboarding: "",
          aoc: "",
          faq: "",
          poll: "",
          quiz: {
            single_select: "",
            multi_select: "",
            slider: "",
            text: "",
          },
          search: {
            found: "",
            notFound: "",
          },
          journal: "",
        });
        setData(formattedData);
      };
      getHistory();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  }, [id]);

  const handleSort = (order: string, type: string, subType?: string) => {
    if ((type === "quiz" || type === "search") && subType) {
      if (sorters?.[type]?.[subType] === order) {
        setSorters((prev: any) => ({
          ...prev,
          [type]: {
            ...prev[type],
            [subType]: "",
          },
        }));
        setData((prev: any) => {
          return {
            ...prev,
            [type]: {
              ...prev[type],
              [subType]: prev[type][subType].sort((a: any, b: any) => {
                const prevDate: any = new Date(a?.dateTime);
                const nextDate: any = new Date(b?.dateTime);
                return nextDate - prevDate;
              }),
            },
          };
        });
      } else {
        setSorters((prev: any) => ({
          ...prev,
          [type]: {
            ...prev[type],
            [subType]: order,
          },
        }));
        setData((prev: any) => {
          return {
            ...prev,
            [type]: {
              ...prev[type],
              [subType]: prev[type][subType].sort((a: any, b: any) => {
                const prevDate: any = new Date(a?.dateTime);
                const nextDate: any = new Date(b?.dateTime);
                const sortedData =
                  order === "asc" ? prevDate - nextDate : nextDate - prevDate;
                return sortedData;
              }),
            },
          };
        });
      }
    } else {
      if (sorters?.[type] === order) {
        setSorters((prev: any) => ({ ...prev, [type]: "" }));
        setData((prev: any) => {
          return {
            ...prev,
            [type]: {
              ...prev[type],
              rows: prev[type]?.rows?.sort((a: any, b: any) => {
                const prevDate: any = new Date(a?.dateTime);
                const nextDate: any = new Date(b?.dateTime);
                return nextDate - prevDate;
              }),
            },
          };
        });
      } else {
        setSorters((prev: any) => ({ ...prev, [type]: order }));
        setData((prev: any) => {
          return {
            ...prev,
            [type]: {
              ...prev[type],
              rows: prev[type]?.rows?.sort((a: any, b: any) => {
                const prevDate: any = new Date(a?.dateTime);
                const nextDate: any = new Date(b?.dateTime);
                const sortedData =
                  order === "asc" ? prevDate - nextDate : nextDate - prevDate;
                return sortedData;
              }),
            },
          };
        });
      }
    }
  };

  // useEffect(() => {
  //   console.log("DATA", data);
  // }, [data]);
  // useEffect(() => {
  //   console.log("SORTERS", sorters);
  // }, [sorters]);
  const SortLabel = ({ type, subType }: { type: string; subType?: string }) => {
    const selectedOrder = subType
      ? sorters?.[type]?.[subType]
      : sorters?.[type];
    return (
      <>
        <StyledSortLabel
          active={selectedOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", type, subType)}
        />
        <StyledSortLabel
          active={selectedOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", type, subType)}
        />
      </>
    );
  };

  return !loading ? (
    <>
      {AccMap.map((accItem: any) => {
        const item = accItem.value;
        const rowData = data?.[item];
        return (
          <Accordion
            key={item}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded.includes(`panel${item}`)}
            onChange={handleChange(`panel${item}`)}
            sx={{
              boxShadow: "none",
              border: "1px solid #E5E7EB",
              borderRadius: "8px !important",
              mb: 2.5,
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box
                sx={{
                  py: 1.5,
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="secondary"
                  mb={0.5}
                >
                  {accItem.label}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 2,
                // display: "flex",
                gap: "16px",
                flexWrap: "wrap",
                mb: 2,
              }}
            >
              {data && data?.[item] ? (
                item === "quiz" ? (
                  QuizMap?.map((quizItem: any) => {
                    const quizData = rowData[quizItem?.value] || [];
                    const choiceQuestions = [
                      "single_select",
                      "multi_select",
                    ]?.includes(quizItem?.value);
                    return (
                      <QuizAccordian
                        item={quizItem}
                        data={quizData}
                        choiceQuestions={choiceQuestions}
                        SortLabel={SortLabel}
                        key={quizItem?.value}
                      />
                    );
                  })
                ) : item === "search" ? (
                  SearchMap?.map((searchItem: any) => {
                    const searchData = rowData[searchItem?.value] || [];
                    return (
                      <SearchAccordian
                        item={searchItem}
                        data={searchData}
                        SortLabel={SortLabel}
                        key={searchItem?.value}
                      />
                    );
                  })
                ) : (
                  <>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {rowData?.headers?.map(
                            (hdr: { label: string; key: string }) => (
                              <StyledTableCell key={hdr?.key}>
                                {hdr?.label}
                                {hdr?.key === "date" && (
                                  <SortLabel type={item} />
                                )}
                              </StyledTableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      {rowData?.rows?.length > 0 && (
                        <TableBody>
                          {rowData?.rows?.map?.((row: any, index: number) => (
                            <TableRow key={index}>
                              {rowData?.headers?.map(
                                (hdr: { label: string; key: string }) => (
                                  <StyledTableCell key={hdr?.key}>
                                    {row[hdr?.key] || "-"}
                                  </StyledTableCell>
                                )
                              )}
                            </TableRow>
                          ))}
                          {/* {rowData.map((item: any, index: any) => (
                          <TableRow id={index}>
                            <StyledTableCell>
                              {item?.patient || "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item?.gender || "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item?.patientNickName || "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item?.patientEthnicity || "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item?.patientLearningPath || "-"}
                            </StyledTableCell>
                          </TableRow>
                        ))} */}
                        </TableBody>
                      )}
                    </Table>
                    {rowData?.rows?.length === 0 && (
                      <NoDataContainer>
                        <Typography variant="body1" color="gray">
                          No data available
                        </Typography>
                      </NoDataContainer>
                    )}
                    {/* {item === "chat" && (
                    <Box
                      width={"100%"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Accordion
                        sx={{
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <IconButton>
                              <ExpandMore sx={{ fontSize: 24 }} />
                            </IconButton>
                          }
                          sx={accInAccHeaderStyle}
                        >
                          Nutrition
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  Introduction to healthy eating
                                </StyledTableCell>
                                <StyledTableCell>Time Stamp</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rowData[0].nutrition.map(
                                (item: any, index: any) => (
                                  <TableRow id={index}>
                                    <StyledTableCell>
                                      {item?.title || "-"}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {item?.timeStamp || "-"}
                                    </StyledTableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <IconButton>
                              <ExpandMore sx={{ fontSize: 24 }} />
                            </IconButton>
                          }
                          sx={accInAccHeaderStyle}
                        >
                          Treatment
                        </AccordionSummary>
                        <AccordionDetails>empty for now</AccordionDetails>
                      </Accordion>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <IconButton>
                              <ExpandMore sx={{ fontSize: 24 }} />
                            </IconButton>
                          }
                          sx={accInAccHeaderStyle}
                        >
                          Complications
                        </AccordionSummary>
                        <AccordionDetails>empty for now</AccordionDetails>
                      </Accordion>
                    </Box>
                  )} */}
                  </>
                )
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    padding: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" color="gray">
                    No data available
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  );
};

export default History;
