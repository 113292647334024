import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  Archive,
  ContentCopy,
  Delete,
  Edit,
  FilterAlt,
  IosShare,
  MoreVert,
  Restore,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AxiosResponse } from "axios";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import http from "../../utils/http";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledSortLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setChatbotFilterId,
  setChatbotPage,
  setChatbotSort,
} from "../../Redux/reducers/chatbotSlice";
import { fetchChatbotsList } from "../../Redux/actions/chatbotAction";
import { useNavigate } from "react-router-dom";
import CreateChatbotModal from "./CreateChatbotModal";

export const downloadFile = async (myData: any) => {
  const fileName = myData.name || myData.title;
  const json = JSON.stringify(myData);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ChatbotList = () => {
  const dispatch = useAppDispatch();
  const {
    loading,
    page,
    sortColumn,
    sortOrder,
    filterId,
    chatbotData,
    totalChatbots,
    type,
    searchText,
    languageFilter,
  } = useAppSelector((state) => state.chatbot);
  const [selectedOption, setSelectedOption] = useState(filterId);
  // const [selectedLanguages, setSelectedLanguages] = useState<string[]>([
  //   ...languageFilter,
  // ]);
  const [menuLoader, setMenuLoader] = useState("");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [showEditModal, setShowEditModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openFilter = Boolean(filterEl);

  // const [languageFilterEl, setLanguageFilterEl] = useState<null | HTMLElement>(
  //   null
  // );
  // const openLanguageFilter = Boolean(languageFilterEl);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      fetchChatbotsList(
        page,
        type,
        filterId,
        searchText,
        sortOrder,
        sortColumn,
        languageFilter
      )
    );
  }, [
    page,
    type,
    filterId,
    searchText,
    sortOrder,
    sortColumn,
    toggleLoader,
    languageFilter,
    dispatch,
  ]);

  const handleChangePage = (_1: any, newPage: number) => {
    dispatch(setChatbotPage(newPage));
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setChatbotSort({ column: "", order: "" }));
    } else {
      dispatch(setChatbotSort({ column, order }));
    }
  };

  const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFilterEl(event?.currentTarget);
  };

  const handleFilterItem = (value: string) => {
    setSelectedOption(value);
  };
  const closeFilterMenu = () => {
    setFilterEl(null);
    if (selectedOption !== filterId) {
      dispatch(setChatbotFilterId(selectedOption));
    }
  };
  const clearFilterMenu = () => {
    dispatch(setChatbotFilterId(""));
    setSelectedOption("");
    setFilterEl(null);
  };

  // const addToLanguageFilters = (value: string) => {
  //   setSelectedLanguages([value]);
  // };
  // const openLanguageFilterMenu = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setLanguageFilterEl(event.currentTarget);
  // };

  // const handleLanguageFilter = () => {
  //   setLanguageFilterEl(null);
  //   if (selectedLanguages[0] !== languageFilter[0]) {
  //     dispatch(setLanguageFilter([...selectedLanguages]));
  //   }
  // };

  // const clearLangFilter = () => {
  //   dispatch(clearLanguageFilter());
  //   setSelectedLanguages([]);
  //   setLanguageFilterEl(null);
  // };
  const openEditModal = () => {
    setShowEditModal(true);
    handleMenuClose();
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const duplicateChat = async (id: string, action: string) => {
    try {
      setMenuLoader(action);
      const res: AxiosResponse = await http.post(`/bots/${id}/duplicate`);
      setMenuLoader("");
      toastMessage("success", res.data?.message);
      setAnchorEl(null);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };
  const exportChatBot = async (id: string, action: string) => {
    try {
      setMenuLoader(action);
      const res: AxiosResponse = await http.get(`/bots/${id}/export`);
      await downloadFile(res.data?.data);
      setMenuLoader("");
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };
  const activateArchiveChat = async (record: any, action: string) => {
    try {
      setMenuLoader(action);
      let body = {
        status: record?.status === "archived" ? "active" : "archived",
      };
      const res: AxiosResponse = await http.put(`/bots/${record?.id}`, body);
      setMenuLoader("");
      toastMessage("success", res.data?.message);
      setAnchorEl(null);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };
  const deleteChat = async (id: string, action: string) => {
    try {
      setMenuLoader(action);
      const res: AxiosResponse = await http.delete(`/bots/${id}`);
      setMenuLoader("");
      toastMessage("success", res.data?.message);
      setAnchorEl(null);
      setToggleLoader((prev) => !prev);
    } catch (err) {
      setMenuLoader("");
      errorToastMessage(err as Error);
    }
  };

  const handleIntegrate = (id: any) => {
    navigate(`/app/chatbot/integrate/${id}`);
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  const refresh = () => {
    setToggleLoader((prev) => !prev);
  };

  return (
    <>
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Name
                <SortLabel column="name" />
              </StyledTableCell>
              {type === "general" && (
                <StyledTableCell>User Title</StyledTableCell>
              )}
              <StyledTableCell>Created By</StyledTableCell>
              <StyledTableCell>
                Update On
                <SortLabel column="updatedAt" />
              </StyledTableCell>
              {/* {type === "general" && (
                <StyledTableCell>Target Audience</StyledTableCell>
              )} */}
              {/* <StyledTableCell>
                Language
                <IconButton onClick={openLanguageFilterMenu}>
                  <FilterAlt
                    color={languageFilter.length > 0 ? "primary" : "disabled"}
                  />
                </IconButton>
              </StyledTableCell> */}
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell align="right">
                <IconButton onClick={openFilterMenu}>
                  <FilterAlt color={filterId ? "primary" : "disabled"} />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {!loading && chatbotData?.length > 0 && (
            <>
              <TableBody>
                {chatbotData?.map((row: any) => (
                  <TableRow key={row?.id}>
                    <StyledTableCell
                      onClick={() =>
                        navigate(`/app/chatbot/builder/${row?.id}`)
                      }
                      sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      {type === "general" && (
                        <Avatar
                          src={row?.imageUrl}
                          sx={{
                            width: 50,
                            height: 50,
                            fontSize: 14,
                            bgcolor: "lightgray",
                            color: "#000",
                          }}
                        >
                          <PhotoSizeSelectActualOutlinedIcon />
                        </Avatar>
                      )}
                      <Box>
                        {row?.name}
                        {type === "general" && (
                          <Typography>
                            {row?.isVisible
                              ? "Visible in Library"
                              : "Not Visible in Library"}
                          </Typography>
                        )}
                      </Box>
                    </StyledTableCell>

                    {type === "general" && (
                      <StyledTableCell sx={{ maxWidth: "250px" }}>
                        <Typography noWrap fontWeight={500} fontSize="16px">
                          {row?.externalName}
                        </Typography>
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      {row?.creator
                        ? row?.creator?.firstName + " " + row?.creator?.lastName
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell>{row?.updatedAt}</StyledTableCell>
                    {/* {type === "general" && (
                      <StyledTableCell>
                        {row?.userType === 0
                          ? "Patient"
                          : row?.userType === 1
                          ? "Caregiver"
                          : "Both Patient and Caregiver"}
                      </StyledTableCell>
                    )} */}
                    {/* <StyledTableCell>{row?.langLabel}</StyledTableCell> */}
                    <StyledTableCell sx={{ textTransform: "capitalize" }}>
                      {row?.status}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e);
                          setSelectedRow(row);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {totalChatbots > pageSize && (
                    <TablePagination
                      sx={TablePaginationStyle}
                      count={totalChatbots}
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[pageSize]}
                      onPageChange={handleChangePage}
                      labelDisplayedRows={paginationLabel}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
        {!loading && chatbotData?.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem onClick={() => handleIntegrate(selectedRow?.id)}>
          <ListItemIcon>
            <MergeTypeIcon />
          </ListItemIcon>
          <ListItemText>Integrate</ListItemText>
        </MenuItem>
        <MenuItem disabled={menuLoader ? true : false} onClick={openEditModal}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={menuLoader ? true : false}
          onClick={() => duplicateChat(selectedRow?.id, "duplicate")}
        >
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
          {menuLoader === "duplicate" && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
        <MenuItem
          disabled={menuLoader ? true : false}
          onClick={() => exportChatBot(selectedRow?.id, "export")}
        >
          <ListItemIcon>
            <IosShare fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export</ListItemText>
          {menuLoader === "export" && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
        {selectedRow?.status !== "draft" && (
          <MenuItem
            disabled={menuLoader ? true : false}
            onClick={() => activateArchiveChat(selectedRow, "status")}
          >
            <ListItemIcon>
              {selectedRow?.status === "archived" ? (
                <Restore fontSize="small" />
              ) : (
                <Archive fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>
              {selectedRow?.status === "archived" ? "Restore" : "Archive"}
            </ListItemText>
            {menuLoader === "status" && (
              <ListItemIcon>
                <CircularProgress size={18} sx={{ ml: 1 }} />
              </ListItemIcon>
            )}
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          disabled={menuLoader ? true : false}
          onClick={() => deleteChat(selectedRow?.id, "delete")}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          {menuLoader === "delete" && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        {["active", "draft", "archived"]?.map((item: any) => (
          <MenuItem
            selected={item === selectedOption}
            key={item}
            onClick={() => handleFilterItem(item)}
          >
            <ListItemIcon>
              {item === selectedOption ? (
                <RadioButtonCheckedIcon fontSize="small" color="primary" />
              ) : (
                <RadioButtonUncheckedIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {item === "archived" ? "archive" : item}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearFilterMenu}>
            Reset
          </Button>
          <Button variant="contained" onClick={closeFilterMenu}>
            OK
          </Button>
        </Box>
      </Menu>
      {/* <Menu
        anchorEl={languageFilterEl}
        open={openLanguageFilter}
        onClose={handleLanguageFilter}
        sx={{
          "& .MuiPaper-root": {
            width: 200,
          },
        }}
      >
        {LANGUAGES.map((language) => (
          <MenuItem
            selected={selectedLanguages.includes(language.value)}
            key={language.value}
            onClick={() => addToLanguageFilters(language.value)}
          >
            <ListItemIcon>
              <Radio checked={selectedLanguages.includes(language.value)} />
            </ListItemIcon>
            <ListItemText sx={{ textTransform: "capitalize" }}>
              {language.label}
            </ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearLangFilter}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleLanguageFilter}>
            OK
          </Button>
        </Box>
      </Menu> */}

      {showEditModal && (
        <CreateChatbotModal
          type={type}
          showModal={showEditModal}
          closeModal={closeEditModal}
          chat={selectedRow}
          refreshPage={refresh}
        />
      )}
    </>
  );
};

export default ChatbotList;
