import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  debounce,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";

import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import { AxiosResponse } from "axios";
import { useAppSelector } from "../../Redux/hooks";

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  // titleZh: yup.string().required("Activity Title in Chinese is Required"),
  // titleTa: yup.string().required("Activity Title in Mandarin is Required"),
  // titleMs: yup.string().required("Activity Title in Malay is Required"),
  // titleTl: yup.string().required("Activity Title in Tagalog is Required"),
  users: yup.array().when("assignToAllPatients", {
    is: (val: boolean) => !val,
    then: (schema) => schema.min(1, "*Please select atleast 1 user"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const CreateActivityModal: React.FC<any> = ({
  showModal,
  closeModal,
  activity,
  refreshPage,
}: any) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const [searchLoader, setSearchLoader] = useState(false);

  const { hasHealthCoachAccess, userId } = useAppSelector(
    (state) => state.user
  );

  const handleUserSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          setSearchLoader(true);
          let url;
          let newIds = [];
          if (hasHealthCoachAccess) {
            url = `/coach/participant?doctorId=${userId}`;
          } else {
            url = `/participants?page=1&size=15&lang=en&status=active`;
          }
          if (value) {
            url += `&search=${value}`;
          }
          const res: AxiosResponse = await http.get(url);
          if (hasHealthCoachAccess) {
            newIds = res.data?.data?.map((user: any) => {
              return {
                id: user?.participant?.id,
                name: user?.participant?.code,
              };
            });
          } else {
            newIds = res.data.data.users.map((user: any) => {
              return {
                id: user?.id,
                name: user?.code,
              };
            });
          }
          setUserList(newIds);
          setSearchLoader(false);
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    [userId, hasHealthCoachAccess]
  );

  useEffect(() => {
    handleUserSearch("");
  }, [handleUserSearch]);

  const submitHandler = async (values: any) => {
    try {
      const body = {
        title: values.title,
        titleZh: "",
        titleTa: "",
        titleMs: "",
        titleTl: "",
        assignedTo:
          !values?.assignToAllPatients && values?.users?.length > 0
            ? values.users.map((item: any) => item.id)
            : [],
        isVisible: values.isVisible === "true" ? true : false,
        userType: values.userType,
        createTemplate: !activity?.assign,
      };
      setButtonLoader(true);
      let res: any;
      if (activity?.id) {
        body.createTemplate = false;
        res = await http.patch(`/daily-task/${activity.id}`, body);
      } else {
        res = await http.post(`/daily-task`, body);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={""} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            title: activity?.title || "",
            // titleZh: activity?.titleZh || "",
            // titleTa: activity?.titleTa || "",
            // titleMs: activity?.titleMs || "",
            // titleTl: activity?.titleTl || "",
            isVisible: activity?.id
              ? activity?.status
                ? "true"
                : "false"
              : "true",

            // showUserId: (activity && activity?.users?.length > 0) || false,
            users: activity?.users || [],
            userType: activity?.id ? activity?.userType : 1,
            assignToAllPatients:
              activity?.id && activity?.userType === 0 ? true : false,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, setFieldValue, values }: any) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel
                  sx={LabelStyle}
                  htmlFor="title"
                  disabled={activity && activity?.assign}
                >
                  Title
                </FormLabel>
                <TextField
                  id="title"
                  placeholder="Title"
                  disabled={activity && activity?.assign}
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title ? errors?.title : " "
                  }
                  {...getFieldProps("title")}
                />
              </FormControl>
              {/*<FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="titleZh">
                  Title (in Chinese)
                </FormLabel>
                <TextField
                  id="titleZh"
                  placeholder="Title (in Chinese)"
                  error={touched?.titleZh && errors?.titleZh ? true : false}
                  helperText={
                    touched?.titleZh && errors?.titleZh ? errors?.titleZh : " "
                  }
                  {...getFieldProps("titleZh")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="titleTa">
                  Title (in Tamil)
                </FormLabel>
                <TextField
                  id="titleTa"
                  placeholder="Title (in Tamil)"
                  error={touched?.titleTa && errors?.titleTa ? true : false}
                  helperText={
                    touched?.titleTa && errors?.titleTa ? errors?.titleTa : " "
                  }
                  {...getFieldProps("titleTa")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="titleMs">
                  Title (in Malay)
                </FormLabel>
                <TextField
                  id="titleMs"
                  placeholder="Title (in Malay)"
                  error={touched?.titleMs && errors?.titleMs ? true : false}
                  helperText={
                    touched?.titleMs && errors?.titleMs ? errors?.titleMs : " "
                  }
                  {...getFieldProps("titleMs")}
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="titleTl">
                  Title (in Tagalog)
                </FormLabel>
                <TextField
                  id="titleTl"
                  placeholder="Title (in Tagalog)"
                  error={touched?.titleTl && errors?.titleTl ? true : false}
                  helperText={
                    touched?.titleTl && errors?.titleTl ? errors?.titleTl : " "
                  }
                  {...getFieldProps("titleTl")}
                />
                </FormControl>*/}
              {/* <FormControl sx={{ ...InputWrapper, mb: 2.5 }}>
                <FormLabel sx={LabelStyle} htmlFor="userType">
                  Target Audience
                </FormLabel>
                <Select id="userType" {...getFieldProps("userType")}>
                  <MenuItem key="patient" value={0}>
                    Patient
                  </MenuItem>
                  <MenuItem key="Caregiver" value={1}>
                    Caregiver
                  </MenuItem>
                  <MenuItem key="both" value={2}>
                    Both Patient and Caregiver
                  </MenuItem>
                </Select>
              </FormControl> */}
              <FormControl sx={{ ...InputWrapper, mb: 2.5 }}>
                <FormLabel sx={LabelStyle} htmlFor="isVisible">
                  Visibility
                </FormLabel>
                <Select id="isVisible" {...getFieldProps("isVisible")}>
                  <MenuItem value="true">Visible</MenuItem>
                  <MenuItem value="false">Invisible</MenuItem>
                </Select>
              </FormControl>

              {/* <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                <FormControlLabel
                  label="Assign To User Id's"
                  control={
                    <Checkbox
                      checked={values?.showUserId}
                      onChange={(event) => {
                        setFieldValue("showUserId", event.target.checked);
                        setFieldValue("userType", event.target.checked ? 1 : 0);
                      }}
                    />
                  }
                />
              </FormControl> */}
              <FormControl
                sx={InputWrapper}
                disabled={values?.assignToAllPatients}
              >
                <FormLabel sx={LabelStyle}>Assign to</FormLabel>
                <Autocomplete
                  multiple
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") handleUserSearch(value);
                  }}
                  onChange={(_1: any, value: any) => {
                    setFieldValue("users", value);
                  }}
                  disabled={values?.assignToAllPatients}
                  forcePopupIcon={!values?.assignToAllPatients}
                  options={userList}
                  getOptionLabel={(option) => option?.name}
                  loading={searchLoader}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  value={values?.users || null}
                  isOptionEqualToValue={(option, value) => {
                    return option?.id === value?.id;
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={
                        touched?.users &&
                        errors?.users &&
                        !values?.assignToAllPatients
                          ? true
                          : false
                      }
                      helperText={
                        touched?.users &&
                        errors?.users &&
                        !values?.assignToAllPatients
                          ? (errors?.users as string)
                          : " "
                      }
                      {...params}
                      placeholder={
                        values?.assignToAllPatients
                          ? "All Patients"
                          : "Search users by typing..."
                      }
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ ...InputWrapper, width: "auto" }}>
                <FormControlLabel
                  label="Assign to all"
                  control={
                    <Checkbox
                      checked={values?.assignToAllPatients}
                      onChange={(event) => {
                        setFieldValue(
                          "assignToAllPatients",
                          event.target.checked
                        );
                        setFieldValue("userType", event.target.checked ? 0 : 1);
                        if (event.target.checked) {
                          setFieldValue("users", []);
                          setUserList([]);
                        }
                      }}
                    />
                  }
                />
              </FormControl>

              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!buttonLoader ? (
                  <>
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateActivityModal;
