import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { setParticipantToggle } from "../../../Redux/reducers/participantsSlice";
import { useAppDispatch } from "../../../Redux/hooks";

const schema = yup.object().shape({
  firstName: yup.string().required("*First name is required"),
  lastName: yup.string().required("*Last name is required"),
  phoneNumber: yup.string().required("*Phone number is required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
};

const AddParticipantModal = ({ showModal, closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setSubmitLoader(true);
      const res: AxiosResponse = await http.post("/users", values);
      toastMessage("success", res.data?.message);
      closeModal();
      dispatch(setParticipantToggle());
      setSubmitLoader(false);
    } catch (error) {
      errorToastMessage(error as Error);
      setSubmitLoader(false);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title="Add Participant" onCloseClick={closeModal} />
        {/* {type === "health_coach" && (
          <Box
            sx={{
              backgroundColor: "rgba(222, 141, 51, 0.10)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#DE8D33",
              borderRadius: "6px",
              width: "80px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Image sx={{ color: "#DE8D33", fontSize: "40px" }}></Image>
          </Box>
        )} */}
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            phoneNumber: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched, getFieldProps }) => (
            <Form>
              <Box sx={{ display: "flex", gap: 1.5 }}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="firstName">
                    First name
                  </FormLabel>
                  <TextField
                    id="firstName"
                    placeholder="First name"
                    error={
                      touched?.firstName && errors?.firstName ? true : false
                    }
                    helperText={
                      touched?.firstName && errors?.firstName
                        ? (errors?.firstName as string)
                        : " "
                    }
                    {...getFieldProps("firstName")}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel sx={LabelStyle} htmlFor="lastName">
                    Last name
                  </FormLabel>
                  <TextField
                    id="lastName"
                    placeholder="Last name"
                    error={touched?.lastName && errors?.lastName ? true : false}
                    helperText={
                      touched?.lastName && errors?.lastName
                        ? (errors?.lastName as string)
                        : " "
                    }
                    {...getFieldProps("lastName")}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="phone">
                    Phone number
                  </FormLabel>
                  <TextField
                    id="phone"
                    placeholder="Phone number"
                    fullWidth
                    inputProps={{
                      maxLength: 10,
                    }}
                    error={
                      touched?.phoneNumber && errors?.phoneNumber ? true : false
                    }
                    helperText={
                      touched?.phoneNumber && errors?.phoneNumber
                        ? (errors?.phoneNumber as string)
                        : " "
                    }
                    {...getFieldProps("phoneNumber")}
                  />
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddParticipantModal;
