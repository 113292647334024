import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../utils/toast";
import { CalendarPicker, PickersDay } from "@mui/x-date-pickers";
import {
  ApptContainer,
  CalendarWrapper,
  RowContainer,
} from "../MyCalendar/style";
import { LoadingContainer } from "../CMS/cms.style";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import ConfirmationModal from "./ConfirmationModal";
import { getColor } from "../../utils/schedule";
import {
  GridContainer,
  dateWrapper,
} from "../UserManagement/Participants/style";

const AssistantAssignSlots = ({ coachId, patientId }: any) => {
  const [loading, setLoading] = useState(false);
  // const [searchLoader, setSearchLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<DateTime | null>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );
  const [month, setMonth] = useState(
    date ? date.toISO() : DateTime.now().toISO()
  );
  const [monthData, setMonthData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  // const [coaches, setCoaches] = useState<any>([]);
  // const [selectedCoach, setSelectedCoach] = useState<any>(null);
  // const [patients, setPatients] = useState<any>([]);
  // const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    if (date) {
      params.set("date", date?.toFormat("dd-LL-yyyy"));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, date]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url = `/consultation/availability?coachId=${coachId}&startDate=${date
          ?.startOf("day")
          .toUTC()
          .toISO()}&endDate=${date?.endOf("day").toUTC().toISO()}`;
        const res: AxiosResponse = await http.get(url);
        const resData = res.data?.data;

        const newData = resData
          ?.filter((item: any) => !item?.assignedTo)
          .filter((item: any) => {
            return DateTime.fromISO(item?.startTime) > DateTime.local();
          })
          .map((item: any) => ({
            id: item?.id,
            // time: DateTime.fromISO(item.dateTime).toFormat("hh:mm a"),
            startTime: DateTime.fromISO(item?.startTime).toFormat("hh:mm a"),
            endTime: item?.endTime
              ? DateTime.fromISO(item?.endTime).toFormat("hh:mm a")
              : DateTime.fromISO(item?.startTime)
                  .plus({ hour: 1 })
                  .toFormat("hh:mm a"),
            status: "available",
          }))
          .sort((a: any, b: any) => {
            return (
              (DateTime.fromFormat(a?.startTime || "", "hh:mm a") as any) -
              (DateTime.fromFormat(b?.startTime || "", "hh:mm a") as any)
            );
          });

        setData(newData || []);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    if (coachId) fetchDetails();
  }, [date, coachId, toggle]);

  useEffect(() => {
    const getMonthData = async () => {
      try {
        let startDate = DateTime.fromISO(month)
          .startOf("month")
          .toUTC()
          .toISO();
        let endDate = DateTime.fromISO(month).endOf("month").toUTC().toISO();

        // if (DateTime.now() > DateTime.fromISO(month)) {
        //   startDate = DateTime.now().startOf("day").toUTC().toISO();
        // }
        if (DateTime.now() > DateTime.fromISO(startDate)) {
          startDate = DateTime.now().toUTC().toISO();
        }
        const res = await http.get(
          `/consultation/unassigned/count?startDate=${startDate}&endDate=${endDate}&coachId=${coachId}`
        );
        setMonthData(res?.data?.data);
      } catch (error) {
        errorToastMessage(error as Error);
      }
    };

    getMonthData();
  }, [coachId, month, date, toggle]);

  const handleSelectSlot = (id: string) => {
    if (selectedSlots.includes(id)) {
      setSelectedSlots((prev) => prev.filter((slot) => slot !== id));
    } else {
      setSelectedSlots((prev) => [...prev, id]);
    }
  };

  // const handleSearch = useMemo(
  //   () =>
  //     debounce(async (value: string) => {
  //       try {
  //         if (value) {
  //           setSearchLoader(true);

  //           let url = `/coach/participant?doctorId=${selectedCoach.id}&search=${value}`;

  //           const res: AxiosResponse = await http.get(url);
  //           const patientsData = res.data?.data?.map((item: any) => {
  //             return {
  //               id: item?.participant?.id,
  //               name: item?.participant?.code,
  //             };
  //           });
  //           setPatients(patientsData);
  //           setSearchLoader(false);
  //         }
  //       } catch (err) {
  //         errorToastMessage(err as Error);
  //         setSearchLoader(false);
  //       }
  //     }, 500),
  //   [selectedCoach]
  // );

  const handleAssign = async () => {
    try {
      setSubmitLoader(true);
      // if (!selectedPatient) {
      //   throw new Error("Please select a patient to assign");
      // }
      if (!selectedSlots.length) {
        throw new Error("Please select a slot to assign");
      }
      setShowModal(true);
      setSubmitLoader(false);
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  // const handleCoachSearch = useMemo(
  //   () =>
  //     debounce(async (value: string) => {
  //       try {
  //         if (value) {
  //           setSearchLoader(true);
  //           let url = `/coach?search=${value}&status=active`;

  //           const res: AxiosResponse = await http.get(url);
  //           const coachesData = res.data?.data?.map((item: any) => {
  //             return {
  //               id: item?.id,
  //               name: item?.firstName + " " + item?.lastName,
  //             };
  //           });
  //           setCoaches(coachesData);
  //           setSearchLoader(false);
  //         }
  //       } catch (err) {
  //         errorToastMessage(err as Error);
  //         setSearchLoader(false);
  //       }
  //     }, 500),
  //   []
  // );

  return (
    <>
      <Typography fontSize={27} fontWeight="bold" sx={{ mb: 2 }}>
        Assign slots
      </Typography>
      <Box
        sx={{
          ...CalendarWrapper,
          boxShadow: "none",
          border: "1px solid #E5E7EB",
          minHeight: "auto",
        }}
      >
        <Box sx={{ ...RowContainer, height: "auto" }}>
          <Box sx={{ flex: 1, maxWidth: "40%" }}>
            <Typography variant="h6" fontWeight="medium" sx={{ mb: "50px" }}>
              Date
            </Typography>
            <Box sx={{ paddingInline: "60px", height: "100%" }}>
              <CalendarPicker
                date={date}
                onMonthChange={(newMonth) => {
                  setMonth(newMonth.toISO());
                }}
                onChange={(newDate: any) => {
                  setDate(newDate);
                }}
                disablePast
                views={["day"]}
                className="calendar-view"
                renderDay={(day, _value, DayComponentProps) => {
                  const dayData = monthData?.find((item) =>
                    DateTime.fromISO(item?.date).hasSame(day, "day")
                  );

                  let backgroundColor = "#d71440";
                  let borderColor = "#d71440 !important";

                  if (dayData?.count >= 2) {
                    backgroundColor = " #07B152";
                    borderColor = " #07B152 !important";
                  } else if (dayData?.count === 1) {
                    backgroundColor = "#FFC20A";
                    borderColor = "#FFC20A !important";
                  }
                  return DayComponentProps?.disabled ? (
                    <PickersDay {...DayComponentProps} />
                  ) : (
                    <PickersDay
                      {...DayComponentProps}
                      sx={{
                        backgroundColor: backgroundColor,
                        color: "#ffffff !important",
                        borderColor: borderColor,
                        "&:hover": {
                          backgroundColor: backgroundColor,
                        },
                      }}
                    />
                  );
                }}
              />
            </Box>
          </Box>
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              mt: "80px",
              pl: "10%",
              borderColor: "#D7D7D7",
            }}
          />
          <Box sx={{ ...ApptContainer, border: "none" }}>
            <Typography variant="h6" fontWeight="medium" sx={{ mb: "80px" }}>
              Time
            </Typography>
            {/* <FormControl sx={{ ...InputWrapper, mb: 5 }}>
              <FormLabel sx={LabelStyle} htmlFor="Health Coach">
                Health Coach
              </FormLabel>
              <Autocomplete
                key={selectedCoach ? selectedCoach?.id : ""}
                filterOptions={(x) => x}
                onInputChange={(_1: any, value: any, reason: string) => {
                  if (reason === "input") handleCoachSearch(value);
                  if (reason === "clear") {
                    setData([]);
                    setSelectedSlots([]);
                    setSelectedPatient(null);
                    setPatients([]);
                  }
                }}
                onChange={(_1: any, value: any) => {
                  setSelectedCoach(value);
                  setSelectedPatient(null);
                  setSelectedSlots([]);
                  setPatients([]);
                }}
                options={coaches}
                value={selectedCoach || null}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                loading={searchLoader}
                loadingText={<CircularProgress size={20} />}
                noOptionsText="No Results"
                clearOnBlur={false}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search..." />
                )}
              />
            </FormControl>
            <FormControl
              sx={{ ...InputWrapper, mb: 5 }}
              disabled={selectedCoach === null}
            >
              <FormLabel sx={LabelStyle} htmlFor="patient">
                Patient
              </FormLabel>
              <Autocomplete
                disabled={selectedCoach === null}
                key={selectedPatient ? selectedPatient?.id : ""}
                filterOptions={(x) => x}
                onInputChange={(_1: any, value: any, reason: string) => {
                  if (reason === "input") handleSearch(value);
                }}
                onChange={(_1: any, value: any) => {
                  setSelectedPatient(value);
                }}
                options={patients}
                value={selectedPatient || null}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                loading={searchLoader}
                loadingText={<CircularProgress size={20} />}
                noOptionsText="No Results"
                clearOnBlur={true}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search..." />
                )}
              />
            </FormControl> */}

            {/* <Typography
              variant="h6"
              fontWeight="medium"
              sx={{ mb: 3, color: selectedCoach === null ? "lightgrey" : "" }}
            >
              Time
            </Typography> */}

            {!loading ? (
              data?.length ? (
                <>
                  <Box sx={GridContainer}>
                    {data?.map((timing) => {
                      const isSelected = selectedSlots.includes(timing.id);

                      return (
                        <Box
                          key={timing.id}
                          sx={{
                            ...dateWrapper,
                            borderColor: getColor(
                              timing?.status,
                              isSelected,
                              "border"
                            ),
                            color: getColor(timing?.status, isSelected, "text"),
                          }}
                          onClick={() => {
                            handleSelectSlot(timing?.id);
                          }}
                        >
                          <Typography
                            fontWeight={500}
                            fontSize={18}
                            fontStyle="normal"
                          >
                            {timing?.startTime} - {timing?.endTime}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: "40px",
                    }}
                  >
                    {!submitLoader ? (
                      <Button variant="contained" onClick={handleAssign}>
                        Assign slots
                      </Button>
                    ) : (
                      <CircularProgress size={18} />
                    )}
                  </Box>
                </>
              ) : (
                <Typography variant="body1" color="gray">
                  No slots available
                </Typography>
              )
            ) : (
              <Box sx={{ ...LoadingContainer, flex: 1 }}>
                <CircularProgress size={25} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          closeModal={closeModal}
          refreshPage={refreshPage}
          patient={patientId}
          slots={selectedSlots}
          setSlots={setSelectedSlots}
          coachId={coachId}
        />
      )}
    </>
  );
};

export default AssistantAssignSlots;
