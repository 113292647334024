import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  NoDataContainer,
  StyledTableCell,
} from "../../../../Common/styles/table";

export const QuizAccordian = ({
  item,
  data,
  choiceQuestions,
  SortLabel,
}: any) => {
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        boxShadow: "none",
        border: "1px solid #E5E7EB",
        borderRadius: "8px !important",
        mb: 2.5,
      }}
      key={item?.value}
    >
      <AccordionSummary
        sx={{
          ".Mui-expanded": {
            m: "12px 0px !important",
          },
        }}
        expandIcon={
          <IconButton>
            <ExpandMore sx={{ fontSize: 24 }} />
          </IconButton>
        }
      >
        <Box sx={{ py: 0.5 }}>
          <Typography
            variant="subtitle2"
            fontWeight="medium"
            color="secondary"
            mb={0.5}
          >
            {item?.label}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 2,
          // display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Unit title</StyledTableCell>
              <StyledTableCell>Question</StyledTableCell>
              <StyledTableCell>
                Answer
                {item?.value === "multi_select" && "s"}
              </StyledTableCell>
              {choiceQuestions && (
                <StyledTableCell>Correct / Wrong</StyledTableCell>
              )}
              <StyledTableCell>
                Date
                <SortLabel type={"quiz"} subType={item?.value} />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {data?.length > 0 && (
            <TableBody>
              {data.map?.((row: any, index: number) => (
                <TableRow key={index}>
                  <StyledTableCell>{row?.title || "-"}</StyledTableCell>
                  <StyledTableCell>{row?.question || "-"}</StyledTableCell>
                  <StyledTableCell>{row?.answer || "-"}</StyledTableCell>
                  {choiceQuestions && (
                    <StyledTableCell>
                      {row?.isCorrect ? "Correct" : "Wrong"}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>{row?.date || "-"}</StyledTableCell>
                </TableRow>
              ))}
              {/* {rowData.map((item: any, index: any) => (
<TableRow id={index}>
  <StyledTableCell>
    {item?.patient || "-"}
  </StyledTableCell>
  <StyledTableCell>
    {item?.gender || "-"}
  </StyledTableCell>
  <StyledTableCell>
    {item?.patientNickName || "-"}
  </StyledTableCell>
  <StyledTableCell>
    {item?.patientEthnicity || "-"}
  </StyledTableCell>
  <StyledTableCell>
    {item?.patientLearningPath || "-"}
  </StyledTableCell>
</TableRow>
))} */}
            </TableBody>
          )}
        </Table>
        {data?.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No data available
            </Typography>
          </NoDataContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export const SearchAccordian = ({ item, data, SortLabel }: any) => {
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        boxShadow: "none",
        border: "1px solid #E5E7EB",
        borderRadius: "8px !important",
        mb: 2.5,
      }}
      key={item?.value}
    >
      <AccordionSummary
        sx={{
          ".Mui-expanded": {
            m: "12px 0px !important",
          },
        }}
        expandIcon={
          <IconButton>
            <ExpandMore sx={{ fontSize: 24 }} />
          </IconButton>
        }
      >
        <Box sx={{ py: 0.5 }}>
          <Typography
            variant="subtitle2"
            fontWeight="medium"
            color="secondary"
            mb={0.5}
          >
            {item?.label}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 2,
          // display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Search term</StyledTableCell>
              {item?.value === "found" && (
                <StyledTableCell>Hits</StyledTableCell>
              )}
              <StyledTableCell>
                Date
                <SortLabel type={"search"} subType={item?.value} />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {data?.length > 0 && (
            <TableBody>
              {data.map?.((row: any, index: number) => (
                <TableRow key={index}>
                  <StyledTableCell>{row?.title || "-"}</StyledTableCell>
                  {item?.value === "found" && (
                    <StyledTableCell>{row?.hits || "-"}</StyledTableCell>
                  )}
                  <StyledTableCell>{row?.date || "-"}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {data?.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No data available
            </Typography>
          </NoDataContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
