import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  FlexContainer,
  GrayBox,
  HeaderStyle,
  ListStyle,
  PolicyBorderedContainer,
  PolicyContainer,
  titleText,
} from "./settings.style";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";

const DeleteAccountSteps = () => {
  const [loading, setLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get("/settings");

        setLogoUrl(res.data.data?.theme?.config?.theme?.light?.logoUrl || "");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchData();
  }, []);

  return !loading ? (
    <>
      <Box sx={HeaderStyle}>
        <img
          src={logoUrl ? logoUrl : "/header-logo.svg"}
          alt="logo"
          style={{
            maxWidth: "71px",
            maxHeight: "71px",
          }}
        />
      </Box>
      <Box sx={GrayBox} />
      <Box
        sx={{ height: "calc(100% - 143px)", overflow: "auto", bgcolor: "#fff" }}
      >
        <Box sx={PolicyContainer}>
          <Box sx={PolicyBorderedContainer}>
            <Typography
              fontWeight={"medium"}
              sx={titleText}
              color={"#111928"}
              mb={"100px"}
            >
              Steps for Deleting your Account
            </Typography>
            <Box
              sx={{
                ...FlexContainer,
                mr: "10%",
                mb: "80px",
              }}
            >
              <img
                src="/step_1.jpg"
                alt="step-1"
                style={{
                  width: "323px",
                  height: "609px",
                  marginRight: "20%",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              />
              <Box>
                <Typography
                  fontSize={37}
                  fontWeight={"regular"}
                  color="#111928"
                  mb={"50px"}
                >
                  Step 1
                </Typography>
                <Typography
                  fontSize={26}
                  fontWeight={"light"}
                  color={"#111928"}
                  sx={ListStyle}
                >
                  From the home screen Tap on{" "}
                  <Typography
                    component="span"
                    fontSize={26}
                    fontWeight={"medium"}
                    color={"#5091CD"}
                  >
                    Profile
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                ...FlexContainer,
                mb: "80px",
              }}
            >
              <Box>
                <Typography
                  fontSize={37}
                  fontWeight={"regular"}
                  color="#111928"
                  mb={"50px"}
                >
                  Step 2
                </Typography>
                <Typography
                  fontSize={26}
                  fontWeight={"light"}
                  color={"#111928"}
                  sx={ListStyle}
                >
                  Tap on the{" "}
                  <Typography
                    component="span"
                    fontSize={26}
                    fontWeight={"medium"}
                    color={"#D80000"}
                  >
                    Delete Account{" "}
                  </Typography>
                  Option in the bottom of the screen.
                </Typography>
              </Box>
              <img
                src="/step_2.jpg"
                alt="step-2"
                style={{
                  width: "340px",
                  height: "626px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  maxWidth: "100%",
                }}
              />
            </Box>
            <Box sx={FlexContainer}>
              <img
                src="/step_3.jpg"
                alt="step-3"
                style={{
                  width: "282px",
                  height: "611px",
                  marginRight: "20%",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              />
              <Box>
                <Typography
                  fontSize={37}
                  fontWeight={"regular"}
                  color="#111928"
                  mb={"50px"}
                >
                  Step 3
                </Typography>
                <Typography
                  fontSize={26}
                  fontWeight={"light"}
                  color={"#111928"}
                  sx={ListStyle}
                >
                  On confirming{" "}
                  <Typography
                    component="span"
                    fontSize={26}
                    fontWeight={"medium"}
                    color={"#D80000"}
                  >
                    Delete{" "}
                  </Typography>
                  , All data related to this account such as <b>Username</b> ,
                  <b> Password </b>
                  and <b>Phone Number</b> will be deleted.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default DeleteAccountSteps;
