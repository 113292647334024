import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import DOMPurify from "dompurify";
import { errorToastMessage } from "../../../utils/toast";
import {
  GrayBox,
  HeaderStyle,
  PolicyBorderedContainer,
  PolicyContainer,
} from "../settings.style";
import http from "../../../utils/http";

const Support = () => {
  const [policyData, setPolicyData] = useState("");
  const [loader, setLoader] = useState(true);
  const { lang } = useParams();
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        setLoader(true);
        const res: AxiosResponse = await http.get(
          `/app/settings/support-page?lang=${lang || "en"}`
        );
        const response: AxiosResponse = await http.get("/settings");

        setLogoUrl(
          response.data.data?.theme?.config?.theme?.light?.logoUrl || ""
        );
        const newText = DOMPurify.sanitize(res.data.data.value || "");
        setPolicyData(newText);
        setLoader(false);
      } catch (err) {
        setLoader(false);
        errorToastMessage(err as Error);
      }
    };

    fetchPolicyData();
  }, [lang]);

  return !loader ? (
    <>
      <Box sx={HeaderStyle}>
        <img
          src={logoUrl ? logoUrl : "/header-logo.svg"}
          alt="logo"
          style={{
            maxWidth: "71px",
            maxHeight: "71px",
          }}
        />
      </Box>
      <Box sx={GrayBox} />
      <Box
        sx={{
          height: "calc(100vh - 143px)",
          overflow: "auto",
          bgcolor: "#fff",
        }}
      >
        <Box sx={PolicyContainer}>
          <Box sx={PolicyBorderedContainer}>
            <div dangerouslySetInnerHTML={{ __html: policyData }}></div>
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Support;
