import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { ChangeEvent, useState } from "react";

import { errorToastMessage } from "../../../utils/toast";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
  data: string;
  id: any;
  type: string;
};

const CvdEditModal = ({
  showModal,
  closeModal,
  data,
  id,
  refreshPage,
  type,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [value, setValue] = useState(data);

  const submitHandler = async () => {
    try {
      if (!value) {
        throw new Error(`${type} value cannot be empty`);
      }

      setButtonLoader(true);
      // const body: any = {
      //   type: type === "cvd" ? "cvd" : "ldl",
      //   goal: value,
      // };
      //   const res = await http.patch(`/activity/goal-settings/${id}`, body);
      // console.log("mock post", body);
      // toastMessage("success", "mock post succesful");
      closeModal();
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={`${type} modal`} onCloseClick={closeModal} />
        <FormControl sx={InputWrapper}>
          <FormLabel sx={LabelStyle} htmlFor="title">
            {type} Type
          </FormLabel>
          <TextField
            type="string"
            placeholder={`Enter ${type} Type`}
            value={value}
            onChange={handleValue}
            inputProps={{ min: 100 }}
          />
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1.5,
            mt: 5,
          }}
        >
          {!buttonLoader ? (
            <>
              <Button variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={submitHandler}>
                Save
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CvdEditModal;
